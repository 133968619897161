import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@aws-amplify/ui-react';

// Custom Theme for Page Not Found
const theme = createTheme({
  name: 'not-found-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          100: '#1f2c46', // Primary color
        },
        accent: {
          100: '#2cb9a2', // Accent color
        },
      },
    },
    components: {
      button: {
        primary: {
          backgroundColor: { value: '#2cb9a2' },
          color: { value: '#ffffff' },
        },
      },
      link: {
        color: { value: '#2cb9a2' },
      },
    },
  },
});

export default function NotFound() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          height: '100vh',
          backgroundColor: '#f9f9f9',
          padding: 4,
        }}
      >
        <Box
          sx={{
            fontSize: '10rem',
            fontWeight: 'bold',
            color: theme.tokens.colors.brand.primary[100],
          }}
        >
          404
        </Box>
        <Typography variant="h4" color="primary" sx={{ marginTop: 2 }}>
          Oops! Page Not Found
        </Typography>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          It seems like you've lost your way. Don't worry, we'll get you back on track.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/"
          sx={{ marginTop: 4 }}
        >
          Go Back Home
        </Button>
      </Box>
    </ThemeProvider>
  );
}
