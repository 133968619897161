import React, { useState } from 'react';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const Faith = () => {
  const [formData, setFormData] = useState({
    religion: '',
    customReligion: '',
    spiritualLeaders: '',
    placeOfWorshipName: '',
    placeOfWorshipAddress: '',
    notes: ''
  });
  const [isEditable, setIsEditable] = useState(true);
  const religions = ["Christianity", "Islam", "Hinduism", "Buddhism", "Judaism", "Other"];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    setIsEditable(false);
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  return (
    <div>
      <FormControl fullWidth margin="normal">
        <InputLabel>Religion</InputLabel>
        <Select
          label="Religion"
          name="religion"
          value={formData.religion}
          onChange={handleChange}
          disabled={!isEditable}
        >
          {religions.map((religion, index) => (
            <MenuItem key={index} value={religion}>{religion}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {formData.religion === "Other" && (
        <TextField
          label="Enter Your Religion"
          name="customReligion"
          value={formData.customReligion}
          onChange={handleChange}
          fullWidth
          margin="normal"
          disabled={!isEditable}
        />
      )}
      <TextField
        label="Spiritual Leaders"
        name="spiritualLeaders"
        value={formData.spiritualLeaders}
        onChange={handleChange}
        fullWidth
        margin="normal"
        disabled={!isEditable}
      />
      <TextField
        label="Place of Worship Name"
        name="placeOfWorshipName"
        value={formData.placeOfWorshipName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        disabled={!isEditable}
      />
      <TextField
        label="Place of Worship Address"
        name="placeOfWorshipAddress"
        value={formData.placeOfWorshipAddress}
        onChange={handleChange}
        fullWidth
        margin="normal"
        disabled={!isEditable}
      />
      <TextField
        label="Notes"
        name="notes"
        value={formData.notes}
        onChange={handleChange}
        fullWidth
        multiline
        rows={4}
        margin="normal"
        disabled={!isEditable}
      />
      <Button variant="contained" color="primary" onClick={handleSave} disabled={!isEditable}>
        Save
      </Button>
      <Button variant="outlined" color="secondary" onClick={handleEdit} disabled={isEditable}>
        Edit
      </Button>
    </div>
  );
};

export default Faith;
