import React from 'react';
import { Avatar, Typography, Button, Box } from '@mui/material';

const DoctorProfilePage = ({ name, category, department, imageUrl, education, address, phoneNumber }) => {
  const getInitials = (name) => name.split(' ').map((n) => n[0]).join('');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
      <Avatar src={imageUrl} alt={`Dr. ${name}`} sx={{ width: 100, height: 100, mb: 2 }}>
        {!imageUrl && getInitials(name)}
      </Avatar>
      <Typography variant="h4" gutterBottom>{name}</Typography>
      <Typography variant="subtitle1" color="text.secondary" gutterBottom>{category} - {department}</Typography>
      <Box sx={{ textAlign: 'left', mt: 2, maxWidth: '100%' }}>
        <Typography variant="body1"><b>Education:</b> {education}</Typography>
        <Typography variant="body1"><b>Address:</b> {address}</Typography>
        <Typography variant="body1"><b>Phone:</b> {phoneNumber}</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 3 }}>
        <Button variant="outlined">Message</Button>
        <Button variant="outlined">Schedule Appointment</Button>
        <Button variant="outlined">View Past Visits</Button>
      </Box>
    </Box>
  );
};

export default DoctorProfilePage;
