import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, List, ListItem, ListItemText } from '@mui/material';
import { fetchAuthSession } from 'aws-amplify/auth';

function ChatComponent() {
  const [ws, setWs] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');

  useEffect(() => {
    async function establishWebsocket() {
      try {
        const session = await fetchAuthSession();
        const accessToken = session.tokens?.accessToken;

        if (!accessToken) {
          throw new Error('No access token found');
        }

        const websocket = new WebSocket(`wss://ws.dev.arcault.com/assistant?token=${accessToken}`);

        websocket.onopen = () => {
          console.log('WebSocket Connected');
        };

        websocket.onmessage = (event) => {
          const data = JSON.parse(event.data);
          console.log(data)
          setMessages((prevMessages) => [...prevMessages, data.message]);
        };

        websocket.onerror = (error) => {
          console.error('WebSocket Error:', error);
        };

        setWs(websocket);

        return () => {
          websocket.close();
          console.log('WebSocket Disconnected');
        };
      } catch (error) {
        console.error('Error establishing WebSocket connection:', error);
      }
    }

    establishWebsocket();
  }, []);

  const sendMessage = () => {
    if (ws && inputMessage.trim()) {
      const message = {
        action: "sendMessage",
        data: inputMessage,
      };
      ws.send(JSON.stringify(message));
      setInputMessage('');
    }
  };

  return (
    <Box>
      <List sx={{ maxHeight: '300px', overflow: 'auto' }}>
        {messages.map((message, index) => (
          <ListItem key={index}>
            <ListItemText primary={message} />
          </ListItem>
        ))}
      </List>
      <TextField
        label="Type your message"
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
        variant="outlined"
        fullWidth
        onKeyDown={(e) => e.key === 'Enter' ? sendMessage() : null}
      />
      <Button onClick={sendMessage} variant="contained" sx={{ marginTop: '10px' }}>
        Send
      </Button>
    </Box>
  );
}

export default ChatComponent;
