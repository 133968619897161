import React, { useState, useEffect } from 'react';
import { Grid, TextField, MenuItem, FormControlLabel, Checkbox, Button, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/RemoveCircleOutline';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';

const relationshipStatuses = [
  'Married', 'Single', 'Divorced', 'Widowed', 'Engaged',
  'Domestic Partnership', 'Civil Union', 'Life Partner'
];

const childRelationships = [
  'Biological', 'Adopted', 'Step-child', 'From Previous Relationship', 'Other'
];

const dependentTypes = [
  'Dependent Child', 'Non-Dependent Child', 'Dependent Adult'
];

const calculateAge = (birthday) => {
  const ageDiff = Date.now() - new Date(birthday).getTime();
  return new Date(ageDiff).getUTCFullYear() - 1970;
};

const FamilyStructure = () => {
  const [contacts, setContacts] = useState({})
  const [partner, setPartner] = useState({})
  const [children, setChildren] = useState([])
  
  const [formData, setFormData] = useState({
    partnerStatus: '',
    partnerData: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      address: '',
      cellPhone: '',
      email: ''
    },
    isDifferentAddress: false,
    dependentData: []
  });

  const axiosWithCredentials = useAxiosWithCredentials();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const response = await axiosWithCredentials.get('/fo/contacts');
      const contacts = response.data
      
      // Filter for the first contact with the "partner" tag
      const partnerContact = contacts.find(contact => 
        contact.tags && contact.tags.includes('partner')
      );
  
      // Filter for all contacts with either "child" or "dependent" tags
      const childrenContacts = contacts.filter(contact => 
        contact.tags && (contact.tags.includes('child') || contact.tags.includes('dependent'))
      );
  
      // Update the state
      if (partnerContact) {
        setPartner(partnerContact);
      }
      if (childrenContacts.length > 0) {
        setChildren(childrenContacts);
      }
  
      // Optionally set the full list of contacts as well
      setContacts(contacts);
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      partnerData: {
        ...prevFormData.partnerData,
        [name]: value
      }
    }));
  };

  const handleStatusChange = (event) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      partnerStatus: event.target.value
    }));
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setFormData(prevFormData => ({
      ...prevFormData,
      isDifferentAddress: isChecked,
      partnerData: {
        ...prevFormData.partnerData,
        address: isChecked ? '' : formData.address
      }
    }));
  };

  const handleDependentInputChange = (index, field, value) => {
    const updatedDependents = formData.dependentData.map((dependent, i) =>
      i === index ? { ...dependent, [field]: value } : dependent
    );
    setFormData(prevFormData => ({
      ...prevFormData,
      dependentData: updatedDependents
    }));
  };

  const addDependent = async () => {
    try {
      const response = await axiosWithCredentials.post('/fo/contacts/family_dependents', {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        relationship: 'Biological',  // Default to Biological
        dependentType: 'Dependent Child',  // Default to Dependent Child
        email: '',
        phone: ''
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        dependentData: [...prevFormData.dependentData, response.data]
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const removeDependent = async (id) => {
    try {
      await axiosWithCredentials.delete(`/fo/contacts/family_dependents/${id}`);
      setFormData(prevFormData => ({
        ...prevFormData,
        dependentData: prevFormData.dependentData.filter(dependent => dependent.id !== id)
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async () => {
    try {
      // Save partner data
      if (formData.partnerStatus !== 'Single') {
        const partnerDataResponse = formData.partnerData.sortKey
          ? await axiosWithCredentials.put(`/fo/contacts/partner/${formData.partnerData.sortKey}`, formData.partnerData)
          : await axiosWithCredentials.post('/fo/contacts/partner', formData.partnerData);

        setFormData(prevFormData => ({
          ...prevFormData,
          partnerData: { ...prevFormData.partnerData, ...partnerDataResponse.data }
        }));
      }

      // Save dependent data
      for (const dependent of formData.dependentData) {
        if (dependent.id) {
          await axiosWithCredentials.put(`/fo/contacts/family_dependents/${dependent.id}`, dependent);
        } else {
          const newDependentResponse = await axiosWithCredentials.post('/fo/contacts/family_dependents', dependent);
          setFormData(prevFormData => ({
            ...prevFormData,
            dependentData: prevFormData.dependentData.map(dep =>
              dep.id === dependent.id ? { ...dep, ...newDependentResponse.data } : dep
            )
          }));
        }
      }

      console.log("Save successful!");
    } catch (err) {
      console.log("Error saving data:", err);
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <Typography variant="h6">Relationship Information</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            select
            name="partnerStatus"
            label="Relationship Status"
            value={formData.partnerStatus || ''}
            onChange={handleStatusChange}
            fullWidth
          >
            {relationshipStatuses.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {formData.partnerStatus !== 'Single' && (
          <>
            <Grid item xs={6}>
              <TextField
                label="Partner's First Name"
                name="firstName"
                value={partner.firstName || ''}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Partner's Last Name"
                name="lastName"
                value={partner.lastName || ''}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Partner's Birthday"
                name="dateOfBirth"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={partner.dateOfBirth || ''}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isDifferentAddress}
                    onChange={handleCheckboxChange}
                  />
                }
                label="My Partner has a different address than mine"
              />
              <TextField
                label="Partner's Address"
                name="address"
                value={partner.address || ''}
                onChange={handleInputChange}
                fullWidth
                disabled={!formData.isDifferentAddress}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Partner's Cell Phone"
                name="cellPhone"
                value={partner.cellPhone || ''}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Partner's Email"
                name="email"
                value={partner.email || ''}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>

      <Typography variant="h6" style={{ marginTop: 20 }}>Dependent Information</Typography>
      <Button onClick={addDependent} variant="contained" color="primary" style={{ marginBottom: 20 }}>
        Add Dependent
      </Button>

      {formData.dependentData.map((dependent, index) => {
        const age = calculateAge(dependent.dateOfBirth);
        return (
          <Grid container spacing={3} sx={{ pt: 2, pb: 2, mb: 2, border: '1px solid #ccc', borderRadius: '5px' }} key={index}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                value={dependent.firstName}
                onChange={(e) => handleDependentInputChange(index, 'firstName', e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Last Name"
                value={dependent.lastName}
                onChange={(e) => handleDependentInputChange(index, 'lastName', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                label="Dependent Type"
                value={dependent.dependentType || 'Dependent Child'}
                onChange={(e) => handleDependentInputChange(index, 'dependentType', e.target.value)}
                fullWidth
                margin="normal"
              >
                {dependentTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                label="Birthday"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={dependent.dateOfBirth}
                onChange={(e) => handleDependentInputChange(index, 'dateOfBirth', e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                select
                label="Relationship"
                value={dependent.relationship || 'Biological'}
                onChange={(e) => handleDependentInputChange(index, 'relationship', e.target.value)}
                fullWidth
                margin="normal"
              >
                {childRelationships.map((relation) => (
                  <MenuItem key={relation} value={relation}>
                    {relation}
                  </MenuItem>
                ))}
              </TextField>
              {age >= 13 && (
                <>
                  <TextField
                    label="Email"
                    type="email"
                    value={dependent.email}
                    onChange={(e) => handleDependentInputChange(index, 'email', e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Cell Phone"
                    type="tel"
                    value={dependent.phone}
                    onChange={(e) => handleDependentInputChange(index, 'phone', e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <IconButton onClick={() => removeDependent(dependent.id)} aria-label="remove">
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}

      <Button onClick={handleSave} variant="contained" color="primary" style={{ marginTop: 20 }}>
        Save
      </Button>
    </div>
  );
};

export default FamilyStructure;
