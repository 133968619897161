import React from 'react';
import { Button, Card, Text } from '@arcault-ui/shared-components'
import './suggestionCards.css';
import Section from '../Section';



const SuggestedCards = () => {

  const suggestions = [
    {
      title: 'Home & Auto',
      surtitle: 'Got documents?',
      body: 'Upload your existing Home, Auto, or Renters Policies. We can optionally create reminders and help you shop the best insurance packages.',
      time: 5,
      action: (<Button type="primary">Add Policy</Button>)
    },
    {
      title: 'Life Insurance',
      surtitle: 'DIY for free',
      body: 'Having life insurance is a key piece of your estate plan. Depending on your needs can be very affordable;',
      time: 15,
      action: (<Button type="primary">Find out more</Button>)
    },
    {
      title: 'Connect',
      surtitle: 'Want professional help?',
      body: 'You can create a proposal that will go out to our network of vetted professionals and they can help get the documents created. Or if you have a specific professional in mind you can grant them access to help get the docs created.',
      time: null,
      action: (<Button type="primary">Find out more</Button>)
    },
  ]

  return (
    <Section className="suggestion-cards">
      <Text variant="subheading">Suggested for you</Text>
      <div style={{ paddingTop: 16, display: 'flex', gap: 16 }}>
        {suggestions.map(suggestion => (  
          <Card footer={suggestion.action}>
            <Text variant="caption">{suggestion.surtitle}</Text>
            <Text variant="h4">{suggestion.title}</Text>
            <div className="card-suggestion-body">
              <Text variant="body-text">
                {suggestion.body}
              </Text>
            </div>
            {suggestion.time && <Text variant="body-text"><em>Est. time {suggestion.time} minutes.</em></Text>}
          </Card>
        ))}
        </div>
    </Section>
  );
};

export default SuggestedCards;