import React, { useState, useEffect } from "react";
import { Card, Text } from "@arcault-ui/shared-components";
import { signOut, getCurrentUser, fetchUserAttributes, fetchAuthSession , decodeJWT} from 'aws-amplify/auth';
import './Creds.css';

const Creds = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [userId, setUserId] = useState(null);
  
  const [error, setError] = useState(null);
  
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
    }, () => {
      setCopySuccess('Failed to copy!');
    });
  };


  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        // Get the current authenticated user's session
        const session = await fetchAuthSession();
        
        setIdToken(session.tokens.idToken.toString())
        setAccessToken(session.tokens.accessToken.toString());
      } catch (err) {
        console.error("Error fetching access token:", err);
        setError("Unable to fetch access token. Please log in.");
      }
    };

    fetchAccessToken();
  }, []);

  return (
    <div style={{ width: 800 }}>
    <Card display="flex" flexWrap="wrap" gap={1} mb={2}>
      <div style={{ paddingBottom: 36 }}>
        <Text variant="h6">Access Token</Text>
        {accessToken ? (
          <>
            <Text variant="access-token">{accessToken}</Text>
            <button onClick={() => copyToClipboard(accessToken)}>Copy to Clipboard</button>
          </>
        ) : (
          <Text color="error">
            {error || "Fetching access token..."}
          </Text>
        )}
      </div>
      <div style={{ paddingBottom: 36 }}>
        <Text variant="h6">Id Token</Text>
        {idToken ? (
          <>
            <Text variant="access-token">{idToken}</Text>
            <button onClick={() => copyToClipboard(idToken)}>Copy to Clipboard</button>
          </>
        ) : (
          <Text color="error">
            {error || "Fetching access token..."}
          </Text>
        )}
      </div>
    </Card>
    </div>
  );
};

export default Creds;