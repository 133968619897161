import React, { useState,useRef, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LockIcon from '@mui/icons-material/Lock';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import axios from 'axios';

const DocumentsTable = ({label, category }) => {
  const [dragOver, setDragOver] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const fileInputRef = useRef(null);
  
  const [documents, setDocuments] = useState([]);
  // ... other states and functions ...
  const axiosWithCredentials = useAxiosWithCredentials()



const handleUpload = async (file) => {
  try {
    // Step 1: Request a pre-signed URL from the Lambda function
    const response = await axiosWithCredentials.post('/files/upload/'+category, {
      fileName: file.name,
      fileType: file.type
    });

    const { uploadUrl } = response.data;
    console.log(uploadUrl)
    // Step 2: Upload the file to S3 using the pre-signed URL
    const s3Response = await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    });

    if (s3Response.status === 200) {
      console.log('File upload successful');
      loadFiles();
    } else {
      console.error('File upload failed');
      // Handle the upload failure (e.g., display error message)
    }
  } catch (error) {
    console.error('Error during file upload', error);
    // Handle any errors that occurred during the upload process
  }
};



  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const handleFileSelect = async (event) => {
  // Get the selected files from the file input
  const files = event.target.files;

  // Check if files are selected
  if (files && files.length > 0) {
    // Assuming you want to handle the first selected file
    const file = files[0];

    // Call your existing upload handler function
    // You might need to modify handleUpload to accept a File object
    await handleUpload(file);
  }
};
  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    // const files = event.dataTransfer.files;
    // console.log(JSON.stringify(files))
    // handleUpload(files);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
        // Process each file individually
        for (let i = 0; i < files.length; i++) {
            console.log(files[i].name)
            console.log(files[i].type)
            handleUpload(files[i]);
        }
    }
  };
  
  
  
  async function handleDownload(fileKey) {
        setDownloading(true);
        setDownloadUrl(null);
        console.log(fileKey)
        try {
            const response = await axiosWithCredentials.post('/files/download', {
              fileKey,
            });
            console.log(JSON.stringify(response))
            setDownloadUrl(response.data.url);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }

        setDownloading(false);
    }

    const onDownloadLinkClick = () => {
        setDownloadUrl(null);
        setDownloading(false);
    };

  const DownloadButton = ({ fileKey }) => {
    return (
      
                
        <IconButton aria-label="download" onClick={() => handleDownload(fileKey)} disabled={downloading}>
            {downloading ? (
                <span>Loading...</span> // Replace with your spinner
            ) : downloadUrl ? (
                <a href={downloadUrl} target="_blank" rel="noopener noreferrer" download>open</a>
            ) : (
                <CloudDownloadIcon />
            )}
        </IconButton>
    );
};
  
  const loadFiles = async () => {
    try {
      // Replace this URL with your actual endpoint
      const response = await axiosWithCredentials.get('/files/'+category+"/");
      console.log(JSON.stringify(response))
      setDocuments(response.data); // Assuming the response contains your files
    // , isLoading: false, downloadUrl: null
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
  };

  useEffect(() => {
    loadFiles();
  }, []); 
  
  
  
const handleDelete = async (documentId) => {
  console.log("Delete function triggered for document ID:", documentId);
  try {
    // Step 1: Request a pre-signed URL from the Lambda function
    const response = await axiosWithCredentials.put('/files/delete', {
      fileKey: documentId
    });

    if (response.status === 200 || response.status === 201) {
      console.log('File delete successful');
      loadFiles();
    } else {
      console.error('File delete failed');
      // Handle the upload failure (e.g., display error message)
    }
  } catch (error) {
    console.error('Error during file delete', error);
    // Handle any errors that occurred during the upload process
  }
};

  
  return (
    <>
    <Box>
  <Paper elevation={3} style={{ padding: '16px', boxSizing: 'border-box', height: 'auto' }}>
    
    <h5><LockIcon/>{label} <sub>Digital Vault</sub></h5>
    <TableContainer component={Paper} style={{ width: '100%'}}>
      <Table stickyHeader size="small" aria-label="documents table">
        <TableHead>
          <TableRow>
            <TableCell>Document Name</TableCell>
            <TableCell>Add On</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.filter(d => d.key).map((doc) => (
            <TableRow key={doc.Key}>
              <TableCell component="th" scope="row">
                {doc.Key?.split('/').pop()}
              </TableCell>
              <TableCell component="th" scope="row">
                {new Date(doc.LastModified).toLocaleDateString('en-US')}
              </TableCell>
              <TableCell align="right">
                <IconButton aria-label="delete" onClick={() => handleDelete(doc.Key)}>
                  <DeleteIcon />
                </IconButton>
                <DownloadButton fileKey={doc.Key} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
      <div 
        onDragOver={handleDragOver} 
        onDragLeave={handleDragLeave} 
        onDrop={handleDrop} 
        style={{ 
          border: dragOver ? '2px dashed blue' : '1px solid transparent', 
          padding: '10px', 
          textAlign: 'center'
        }}
      >
        <Typography>Drag and Drop Files Here</Typography>
        <IconButton aria-label="upload" onClick={handleUpload}>
          <CloudUploadIcon />
        </IconButton>
        <input
          type="file"
          style={{ display: 'none' }}
          ref={fileInputRef}
          onClick={handleUploadClick}
          onChange={handleFileSelect} // handle the file selection
        />
      </div>
      <Table stickyHeader size="small" aria-label="documents table">
        {/* Table Content */}
      </Table>
    </TableContainer>
    </Paper>
</Box>
    </>
  );
};

export default DocumentsTable;


