import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

export default function RequireAuth({ children }) {
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const location = useLocation();

    // While `authStatus` is still configuring, we can show a loading indicator
    if (authStatus === 'configuring') {
        return <p>Loading...</p>;
    }

    // Redirect to signIn if the user is not authenticated
    if (authStatus !== 'authenticated') {
        return <Navigate to="/signIn" state={{ from: location }} replace />;
    }

    // Once authenticated, render the children or Outlet
    return children ? children : <Outlet />;
}