import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Terms = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Box>
        <Typography variant="h4" gutterBottom>
          Terms of Use
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Arcault! These Terms of Use ("Terms") govern your access to and use of our digital life planning platform, including any content, functionality, and services offered on or through our platform (the "Service"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By using our Service, you accept and agree to be bound and abide by these Terms and our Privacy Policy, found at [link to privacy policy], which is incorporated herein by reference. If you do not want to agree to these Terms or the Privacy Policy, you must not access or use the Service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          2. Changes to the Terms
        </Typography>
        <Typography variant="body1" paragraph>
          We may revise and update these Terms from time to time at our sole discretion. All changes are effective immediately when we post them. Your continued use of the Service following the posting of revised Terms means that you accept and agree to the changes.
        </Typography>

        <Typography variant="h5" gutterBottom>
          3. User Responsibilities
        </Typography>
        <Typography variant="body1" paragraph>
          You agree to use the Service in a manner that is lawful and in compliance with these Terms. You must not:
        </Typography>
        <ul>
          <li>Use the Service for any unlawful purpose or in violation of any applicable laws.</li>
          <li>Distribute viruses, malicious code, or other harmful components.</li>
          <li>Engage in any activity that interferes with or disrupts the Service or its servers.</li>
          <li>Attempt to gain unauthorized access to any part of the Service, other accounts, computer systems, or networks connected to the Service.</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          4. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          The Service and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by Arcault, its licensors, or other providers of such material and are protected by copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
        </Typography>

        <Typography variant="h5" gutterBottom>
          5. User Content
        </Typography>
        <Typography variant="body1" paragraph>
          You retain ownership of any content you post, upload, or otherwise make available through the Service ("User Content"). However, by providing User Content, you grant Arcault a non-exclusive, royalty-free, worldwide license to use, modify, publicly perform, display, reproduce, and distribute such User Content for the purposes of operating and providing the Service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          6. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          We may terminate or suspend your access to all or part of the Service at any time, without notice, for any reason, including, without limitation, any violation of these Terms.
        </Typography>

        <Typography variant="h5" gutterBottom>
          7. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          To the fullest extent permitted by applicable law, in no event will Arcault, its affiliates, licensors, service providers, employees, agents, officers, or directors be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, revenue, data, use, goodwill, or other intangible losses, arising out of or in connection with your access to, use of, or inability to use the Service; any conduct or content of any third party on the Service; any content obtained from the Service; and unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
        </Typography>
        
        <Typography variant="h5" gutterBottom>
          8. Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          
        </Typography>
        
        <Typography variant="h5" gutterBottom>
          9. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          
        </Typography>
        
        <Typography variant="h5" gutterBottom>
          10. Third Party Links and Services
        </Typography>
        <Typography variant="body1" paragraph>
          
        </Typography>
        <Typography variant="h5" gutterBottom>
          11. Fee and Payment Terms
        </Typography>
        <Typography variant="body1" paragraph>
          
        </Typography>
        <Typography variant="h5" gutterBottom>
          12. Termination and Suspension
        </Typography>
        <Typography variant="body1" paragraph>
          
        </Typography>
        <Typography variant="h5" gutterBottom>
          13. Disclaimers and Warranties
        </Typography>
        <Typography variant="body1" paragraph>
          
        </Typography>
        <Typography variant="h5" gutterBottom>
          14. Indemnification
        </Typography>
        <Typography variant="body1" paragraph>
          
        </Typography>
        <Typography variant="h5" gutterBottom>
          15. Governing Law and Jurisdiction
        </Typography>
        <Typography variant="body1" paragraph>
          
        </Typography>
        <Typography variant="h5" gutterBottom>
          16. Dispute Resolution
        </Typography>
        <Typography variant="body1" paragraph>
          
        </Typography>
        <Typography variant="h5" gutterBottom>
          17. Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          
        </Typography>
        <Typography variant="h5" gutterBottom>
          18. Miscellaneous
        </Typography>
        <Typography variant="body1" paragraph>
          
        </Typography>
        </Box>
            </Container>
      );
};

export default Terms;
