import React, { useState, useEffect } from 'react';
import Tree from 'react-d3-tree';
import { Button, Dialog, TextField, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const familyTreeData = {
  name: "John Doe", // Father
  children: [
    {
      name: "Jane Doe", // Mother
      children: [
        { name: "Child 1" },
        { name: "Child 2" }
      ]
    },
    {
      name: "Grandpa Joe", // Father's Father
      spouse: "Grandma Josephine", // Father's Mother
      side: "paternal",
    },
    {
      name: "Grandpa Mike", // Mother's Father
      spouse: "Grandma Michelle", // Mother's Mother
      side: "maternal",
    }
  ]
};

function FamilyTree() {
  const [treeData, setTreeData] = useState({});

  useEffect(() =>{
      setTreeData(familyTreeData);
  }, [])



  const [open, setOpen] = useState(false);
  const [newMemberName, setNewMemberName] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addFamilyMember = () => {
    // This is a simplified example. You would need to determine where to add the new member in the tree
    const updatedTree = { ...treeData };
    updatedTree.children.push({ name: newMemberName });
    setTreeData(updatedTree);
    handleClose();
  };

  return (
    <div>
      <div id="treeWrapper" style={{ width: '100%', height: '100vh' }}>
        <Tree data={treeData} />
      </div>
      <Button variant="contained" onClick={handleOpen}>
        Add Family Member
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add a New Family Member</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            variant="standard"
            value={newMemberName}
            onChange={(e) => setNewMemberName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={addFamilyMember}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FamilyTree;
