// Logout.js
import React, { useEffect } from 'react';
import { signOut } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    signOut()
      .then(() => {
        console.log('Logged out successfully');
        navigate('/signIn'); // Redirect to login or home page after logout
      })
      .catch((error) => {
        console.error('Error during logout:', error);
        navigate('/error'); // Redirect to an error page if needed
      });
  }, [navigate]);

  return (
    <div>
      <h2>Logging out...</h2>
    </div>
  );
};

export default Logout;
