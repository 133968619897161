import React, {useState}  from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { Link } from 'react-router-dom';

import { Outlet } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Check from '@mui/icons-material/Check';

import Breadcrumbs from '../common/Breadcrumbs'

// import axios from '../api/axios';
// import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';
// import useAuth from "../hooks/useAuth"
// import EstatePlanning from './EstatePlanning';
// import Family from './Family';
// import Education from './Education';
// import Me from './Me';
// import Career from './Career';
// import Chat from './Chat';
// import ContactsPage from './ContactsPage'
// import ProfileMenu from './ProfileMenu'
// import MedicinesComponent from './MedicinesComponent'
// import CareTeam from './CareTeam'
// import DigitalAccountManagementPage from './DigitalAccountManagementPage'
// import OnlineAccounts from './OnlineAccounts'
// import WorkoutLog from './WorkoutLog'
// import EducationComponent from './EducationComponent'

// import Settings from './forms/Settings';
// import SettingsTable from './SettingsTable';
// import Relationships from './forms/Relationships';
// import Insurance from './forms/Insurance';
// import HealthPlan from './forms/HealthPlan';
// import Assets from './forms/Assets';
// import CollapsibleMenu from './CollapsibleMenu'
// import Identification from './Identification'
// import Faith from './Faith'
// import CharitiesPage from './CharitiesPage'
// import WorkExperienceComponent from './WorkExperienceComponent'
// import MilitaryServiceComponent from './MilitaryServiceComponent'
// import CollectionManager from './CollectionManager'
// import TaxRecords from './TaxRecords'
// import AccountantManager from './AccountantManager'
// import EstatePlanManager from './EstatePlanManager'
// import FamilyTree from './FamilyTree'




const drawerWidth = 240;

function LeftBarProfile(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeForm, setActiveForm] = React.useState('basicInfo');

  const infoSettings = {
    "Dark Mode": false,
    "Email Notifications": true,
    "Auto Sync": false
  };
  const peopleSettings = {
    "Dark Mode": false,
    "Email Notifications": true,
    "Auto Sync": false
  };
  


  

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

    const [showInfo, setShowInfo] = useState(false);
  const [showPeople, setShowPeople] = useState(false);
  const [showHealth, setShowHealth] = useState(false);
  const [showAffiliations, setShowAffiliations] = useState(false);
  const [showEmployment, setShowEmployment] = useState(false);
  const [showPhysical, setShowPhysical] = useState(false);
  const [showDigital, setShowDigital] = useState(false);
  const [showInsurance, setShowInsurance] = useState(false);
  const [showTax, setShowTax] = useState(false);
  const [showFinance, setShowFinance] = useState(false);
  const [showLegal, setShowLegal] = useState(false);
  const [showElder, setShowElder] = useState(false);
  const [showEstate, setShowEstate] = useState(false);
  const [showLegacy, setShowLegacy] = useState(false);
  const [showBills, setShowBills] = useState(false);
  const [showSecrets, setShowSecrets] = useState(false);
  
  const toggleSecrets = () => {
    setShowSecrets(!showSecrets);
  };
  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };
  const togglePeople = () => {
    setShowPeople(!showPeople);
  };
  const toggleHealth = () => {
    setShowHealth(!showHealth);
  };
  const toggleAffiliations = () => {
    setShowAffiliations(!showAffiliations);
  };
  const toggleEmployment = () => {
    setShowEmployment(!showEmployment);
  };
  const togglePhysical = () => {
    setShowPhysical(!showPhysical);
  };
  const toggleDigital = () => {
    setShowDigital(!showDigital);
  };
  const toggleInsurance = () => {
    setShowInsurance(!showInsurance);
  };
  const toggleTax = () => {
    setShowTax(!showTax);
  };
  const toggleFinance = () => {
    setShowFinance(!showFinance);
  };
  const toggleLegal = () => {
    setShowLegal(!showLegal);
  };
  const toggleElder = () => {
    setShowElder(!showElder);
  };
  const toggleEstate = () => {
    setShowEstate(!showEstate);
  };
  const toggleLegacy = () => {
    setShowLegacy(!showLegacy);
  };
  const toggleBills = () => {
    setShowBills(!showBills);
  };

  return (
        <Drawer variant="permanent" anchor="left" sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',top: '82px'
          },
          
        }}>
      
      <Paper sx={{ width: 320 }}>
      <MenuList dense>
        <MenuItem onClick={toggleInfo}>
          <ListItemText>Info</ListItemText>
        </MenuItem>
        {showInfo && (
            <>
              <MenuItem component={Link} to="/profile/basic">
                <ListItemText inset>Basic Info</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/relationship">
                <ListItemText inset>Relationship Info</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/dependants">
                <ListItemText inset>Dependants Info</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/id">
                <ListItemText inset>Identification</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/settings/info">
                <ListItemText inset>Settings</ListItemText>
              </MenuItem>
            </>
          )}
        <MenuItem onClick={togglePeople}>
          <ListItemText>People</ListItemText>
        </MenuItem>
        {showPeople && (
            <>
              <MenuItem component={Link} to="/profile/family">
                <ListItemText inset>Family</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/contacts">
                <ListItemText inset>All Contacts</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/tree">
                <ListItemText inset>Family Tree</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/settings/people">
                <ListItemText inset>Settings</ListItemText>
              </MenuItem>
            </>
          )}
        <MenuItem onClick={toggleHealth}>
          <ListItemText>Health</ListItemText>
        </MenuItem>
        {showHealth && (
            <>
              <MenuItem component={Link} to="/profile/care">
                <ListItemText inset>Care Team</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/medicines">
                <ListItemText inset>Medicines</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/fitness">
                <ListItemText inset>Fitness</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/settings/health">
                <ListItemText inset>Settings</ListItemText>
              </MenuItem>
            </>
          )}
        <MenuItem onClick={toggleAffiliations}>
          <ListItemText>Affiliations</ListItemText>
        </MenuItem>
        {showAffiliations && (
            <>
              <MenuItem component={Link} to="/profile/faith">
                <ListItemText inset>Faith</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/charities">
                <ListItemText inset>Charities</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/education">
                <ListItemText inset>Education</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/favorites">
                <ListItemText inset>Favorite Things</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/settings/affiliations">
                <ListItemText inset>Settings</ListItemText>
              </MenuItem>
            </>
          )}
        <MenuItem onClick={toggleEmployment}>
          <ListItemText>Employment</ListItemText>
        </MenuItem>
        {showEmployment && (
            <>
              <MenuItem component={Link} to="/profile/work">
                <ListItemText inset>Work History</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/military">
                <ListItemText inset>Military</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/settings/employment">
                <ListItemText inset>Settings</ListItemText>
              </MenuItem>
            </>
          )}
        <MenuItem onClick={togglePhysical}>
          <ListItemText>Physical Assets</ListItemText>
        </MenuItem>
        {showPhysical && (
            <>
              <MenuItem component={Link} to="/profile/assets">
                <ListItemText inset>Property & Vehicles</ListItemText>
              </MenuItem>
              <MenuItem  component={Link} to="/profile/collections">
                <ListItemText inset>Collections & Items</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/settings/physical">
                <ListItemText inset>Settings</ListItemText>
              </MenuItem>
            </>
          )}
        <MenuItem onClick={toggleDigital}>
          <ListItemText>Digital Assets</ListItemText>
        </MenuItem>
        {showDigital && (
            <>
              <MenuItem component={Link} to="/profile/digital">
                <ListItemText inset>Inventory</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/online">
                <ListItemText inset>Accounts</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/settings/digital">
                <ListItemText inset>Settings</ListItemText>
              </MenuItem>
            </>
          )}
        <MenuItem onClick={toggleInsurance}>
          <ListItemText>Insurance</ListItemText>
        </MenuItem>
        {showInsurance && (
            <>
              <MenuItem component={Link} to="/profile/inspcl">
                <ListItemText inset>Property & Casualty</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/inshealth">
                <ListItemText inset>Health</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/settings/insurance">
                <ListItemText inset>Settings</ListItemText>
              </MenuItem>
            </>
          )}
        <MenuItem onClick={toggleTax}>
          <ListItemText>Taxes</ListItemText>
        </MenuItem>
        {showTax && (
            <>
              <MenuItem  component={Link} to="/profile/accountant">
                <ListItemText inset>My Accountant</ListItemText>
              </MenuItem>
              <MenuItem  component={Link} to="/profile/taxrecords">
                <ListItemText inset>Tax Records</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/settings/taxes">
                <ListItemText inset>Settings</ListItemText>
              </MenuItem>
            </>
          )}
        <MenuItem onClick={toggleFinance}>
          <ListItemText>Finances</ListItemText>
        </MenuItem>
        {showFinance && (
            <>
              <MenuItem component={Link} to="/profile/banking">
                <ListItemText inset>Banking</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Retirement</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Investment</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Digital Assets</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Cash & Equivalents</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Settings</ListItemText>
              </MenuItem>
            </>
          )}
        <MenuItem onClick={toggleLegal}>
          <ListItemText>Legal</ListItemText>
        </MenuItem>
        {showLegal && (
            <>
              <MenuItem>
                <ListItemText inset>My Attorney</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Settings</ListItemText>
              </MenuItem>
            </>
          )}
        <MenuItem onClick={toggleElder}>
          <ListItemText>Eldercare</ListItemText>
        </MenuItem>
        {showElder && (
            <>
              <MenuItem>
                <ListItemText inset>Preferences</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Finances</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Settings</ListItemText>
              </MenuItem>
            </>
          )}
        <MenuItem onClick={toggleEstate}>
          <ListItemText>Estate Planning</ListItemText>
        </MenuItem>
        {showEstate && (
            <>
              <MenuItem  component={Link} to="/profile/estate/executor">
                <ListItemText inset>Executor</ListItemText>
              </MenuItem>
              <MenuItem  component={Link} to="/profile/estate/healthcareproxy">
                <ListItemText inset>Health Care Proxy</ListItemText>
              </MenuItem>
              <MenuItem  component={Link} to="/profile/estate/guardianship">
                <ListItemText inset>Guardianship Designation</ListItemText>
              </MenuItem>
              <MenuItem  component={Link} to="/profile/estate/livingwill">
                <ListItemText inset>Living Will</ListItemText>
              </MenuItem>
              <MenuItem  component={Link} to="/profile/estate/poa">
                <ListItemText inset>Durable Power of Attorney</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => setActiveForm('estate.manager')}>
                <ListItemText inset>Estate Plan</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Power of Attorney</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Directives</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Settings</ListItemText>
              </MenuItem>
            </>
          )}
        <MenuItem onClick={toggleLegacy}>
          <ListItemText>Legacy</ListItemText>
        </MenuItem>
        {showLegacy && (
            <>
              <MenuItem>
                <ListItemText inset>Digital Content</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Physical Content</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Financial Transactions</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Philanthropy</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Wall</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Digital Journal</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Settings</ListItemText>
              </MenuItem>
            </>
          )}
        <MenuItem onClick={toggleBills}>
          <ListItemText>Bills</ListItemText>
        </MenuItem>
        {showBills && (
            <>
              <MenuItem>
                <ListItemText inset>Escrow Account</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Virtual Card</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText inset>Bill Pay</ListItemText>
              </MenuItem>
            </>
          )}
         <MenuItem onClick={toggleSecrets}>
          <ListItemText>Secrets</ListItemText>
        </MenuItem>
        {showSecrets && (
            <>
              <MenuItem component={Link} to="/profile/secrets">
                <ListItemText inset>List</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/profile/settings/secrets">
                <ListItemText inset>Settings</ListItemText>
              </MenuItem>
            </>
          )} 
        
        <Divider />
        
        <MenuItem>
          <ListItemText>...</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemText>...</ListItemText>
        </MenuItem>
      </MenuList>
    </Paper>
      
    </Drawer>
  );
}


export default LeftBarProfile;