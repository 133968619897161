import React, { useState } from 'react';
import { Button, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

const Collections = ({ onSelectCollection }) => {
  const [collections, setCollections] = useState([]);
  const [currentCollection, setCurrentCollection] = useState({ name: '', description: '', type: '' });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Handlers for opening, closing dialog, changing input, saving, and deleting collections
// Handlers for dialog, change, save, delete similar to previous components
const handleOpenDialog = (experience = {}) => {
    setCurrentCollection(experience);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleChange = (e) => {
    setCurrentCollection({ ...setCurrentCollection, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
     
    if (currentCollection.id) {
      setCollections(collections.map(exp => exp.id === currentCollection.id ? currentCollection : exp));
    } else {
      setCollections([...collections, { ...currentCollection, id: Date.now() }]);
    }
    handleCloseDialog();
  };

  const handleDelete = (id) => {
    setCollections(collections.filter(exp => exp.id !== id));
  };

  return (
    <div>
      <Button onClick={() => handleOpenDialog()}>Add Collection</Button>
      <List>
        {collections.map(collection => (
          <ListItem key={collection.id} button >
            <ListItemText primary={collection.name} secondary={collection.description} />
            <Button onClick={() => handleOpenDialog(collection)}>Open</Button>
            <Button onClick={() => handleOpenDialog(collection)}>Edit</Button>
            <Button onClick={() => handleDelete(collection.id)}>Delete</Button>
          </ListItem>
        ))}
      </List>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>{currentCollection.id ? 'Edit Service Record' : 'Add Service Record'}</DialogTitle>
        <DialogContent>
          
          <TextField
            label="Name"
            name="name"
            value={currentCollection.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            name="description"
            value={currentCollection.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Collections;