import {useLocation, Navigate, Outlet} from "react-router-dom";
import {useAuthenticator} from "@aws-amplify/ui-react"
 export default function Setup({children}){
     const location = useLocation();
     const {route} = useAuthenticator(context => [context.route]);
     console.log(route)
     if(route !== "authenticated"){
         return <Navigate to="/login" state={{from: location}} replace />
     }
     return <Navigate to="/" state={{from: location}} replace />;
 }