import React, { useState } from 'react';
import { TextField, Button, List, ListItem, ListItemText } from '@mui/material';

const Fitness = () => {
  const [workouts, setWorkouts] = useState([]);
  const [newWorkout, setNewWorkout] = useState({ type: '', duration: '', notes: '', date: '' });

  const handleInputChange = (e) => {
    setNewWorkout({ ...newWorkout, [e.target.name]: e.target.value });
  };

  const addWorkout = () => {
    setWorkouts([...workouts, newWorkout]);
    setNewWorkout({ type: '', duration: '', notes: '', date: '' }); // Reset the form
  };

  return (
    <div>
      <h2>Workout Log</h2>
      <TextField
        label="Type"
        name="type"
        value={newWorkout.type}
        onChange={handleInputChange}
      />
      <TextField
        label="Duration"
        name="duration"
        value={newWorkout.duration}
        onChange={handleInputChange}
      />
      <TextField
        label="Notes"
        name="notes"
        value={newWorkout.notes}
        onChange={handleInputChange}
      />
      <TextField
        label="Date"
        name="date"
        type="date"
        value={newWorkout.date}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
      />
      <Button onClick={addWorkout}>Add Workout</Button>

      <List>
        {workouts.map((workout, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={`${workout.type} - ${workout.date}`}
              secondary={`Duration: ${workout.duration}, Notes: ${workout.notes}`}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default Fitness;
