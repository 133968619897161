import axios from 'axios';

//This comes in from the build based on the env
const BASE_URL = process.env.REACT_APP_API_DOMAIN_NAME;

export default axios.create({
    baseURL: BASE_URL
})

export const axiosWithCredentials =  axios.create({
    baseURL: BASE_URL,
    withCredentials :true
})