import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import axios from 'axios';
import DigitalVault from '../common/digitalVault/DigitalVault';
import InfoAssetsTable from '../common/infoAssets/InfoAssetsTable';


import Button from '@mui/material/Button';
import Tooltip, {  tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';


const category = "insurance"
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const PCLInsurance = () => {
  const axiosWithCredentials = useAxiosWithCredentials()
  
  const [documents, setDocuments] = useState([]);

  const loadFiles = async () => {
    try {
      // Replace this URL with your actual endpoint
      const response = await axiosWithCredentials.get('/files/'+category+"/");
      console.log(JSON.stringify(response))
      setDocuments(response.data); // Assuming the response contains your files
    // , isLoading: false, downloadUrl: null
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
  };

  useEffect(() => {
    loadFiles();
  }, []); 
  
  
  const handleUpload = async (file) => {
  try {
    // Step 1: Request a pre-signed URL from the Lambda function
    const response = await axiosWithCredentials.post('/files/upload/'+category, {
      fileName: file.name,
      fileType: file.type
    });

    const { uploadUrl } = response.data;
    console.log(uploadUrl)
    // Step 2: Upload the file to S3 using the pre-signed URL
    const s3Response = await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    });

    if (s3Response.status === 200) {
      console.log('File upload successful');
      loadFiles();
    } else {
      console.error('File upload failed');
      // Handle the upload failure (e.g., display error message)
    }
  } catch (error) {
    console.error('Error during file upload', error);
    // Handle any errors that occurred during the upload process
  }
};

const handleDelete = async (documentId) => {
  console.log("Delete function triggered for document ID:", documentId);
  try {
    // Step 1: Request a pre-signed URL from the Lambda function
    const response = await axiosWithCredentials.put('/files/delete', {
      fileKey: documentId
    });

    if (response.status === 200 || response.status === 201) {
      console.log('File delete successful');
      loadFiles();
    } else {
      console.error('File delete failed');
      // Handle the upload failure (e.g., display error message)
    }
  } catch (error) {
    console.error('Error during file delete', error);
    // Handle any errors that occurred during the upload process
  }
};

const handleDownload = (document) => {
  console.log("Download function triggered for document:", document);
  // Implement download logic here
};

const fields = [
    { id: 'policy_number', label: 'Policy Number',column: true, form: true},
    {id: 'type', label: 'Type',column: true, form: true},
    {id: 'end_date', label: 'In Effect Until',column: true, form: true},
  ];
  

    // This is where you'll define your HealthPlan form structure
      return (
        <>
        <InfoAssetsTable
        title="Insurance Policies"
        fields={fields}
        service="insurance"
      />
        <h6>Suggested for you</h6>
    <Grid container spacing={2}>
        <Grid item xs={12} sm={4} >
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Got documents?
        </Typography>
        <Typography variant="h5" component="div">
          Home & Auto
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        About 5 Min
        </Typography>
        <Typography variant="body2">
          Upload your existing Home, Auto, or Renters Policies. We can optionally create reminders and help you shop the best insurance packages.
        </Typography>
      </CardContent>
      <CardActions>
      </CardActions>
    </Card>
        </Grid>
      <Grid item xs={12} sm={4} >
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          DIY for free
        </Typography>
        <Typography variant="h5" component="div">
          Life Insurance
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        About 15 Min
        </Typography>
        <Typography variant="body2">
          Having life insurance is a key piece of your estate plan. Depending on your needs can be very affordabe;
        </Typography>
      </CardContent>
      <CardActions sx={{ mt: 'auto' }}>
        <Button size="small">Get Started</Button>
      </CardActions>
    </Card>
        </Grid>
        <Grid item xs={12} sm={4} >
        <Card  sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Want professional help?
        </Typography>
        <Typography variant="h5" component="div">
          Connect
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          
        </Typography>
        <Typography variant="body2">
          You can create a proposal that will go our to our network of vetted professionals and they can help get the documents created. Or if you have a specific professional in mind you can grant them access to help get the docs created.
        </Typography>
      </CardContent>
      <CardActions sx={{ mt: 'auto' }}>
        <Button size="small">Get Started</Button>
      </CardActions>
    </Card>
    </Grid>
    </Grid>
    
    
   <DigitalVault
      category="insurance"
      label="Insurance Documents"
    />
    </>
  );
}
export default PCLInsurance
