import React, { useState, useEffect } from 'react';
import { Switch, FormControlLabel, TextField, Button, Box, Typography } from '@mui/material';
import RsaKeyInstructions from './RsaKeyInstructions'


import {useSettings} from '../../../context/SettingsContext'


function EncryptionKeyToggle() {
  const [useCustomKey, setUseCustomKey] = useState(false);
  const [publicKey, setPublicKey] = useState('');
  const [keyName, setKeyName] = useState('');
  const {settings = {} , updateSettings} = useSettings();

    useEffect(() => {
      setUseCustomKey(settings.secrets?.useCustomKey)
      setPublicKey(settings.secrets?.publicKey)
      setKeyName(settings.secrets?.keyName)
    }, []); 
  
  const handleToggleChange = (event) => {
    setUseCustomKey(event.target.checked);
    console.log('Toggle:', event.target.checked)
    // Optionally reset publicKey and inputName when toggled off
    if (!event.target.checked) {
      setPublicKey('');
      setKeyName('');
      
      updateSettings({
      ...settings,
      secrets: {
        ...settings.secrets,
        useCustomKey: event.target.checked , publicKey: '', keyName: ''
      },
    });
    }
    
  };

  const handleSave = () => {
    updateSettings({
      ...settings,
      secrets: {
        ...settings.secrets,
        useCustomKey, publicKey, keyName
      },
    });
  };

  const handleUpdate = () => {
    // Implement update logic here
    console.log('Updating publicKey:', settings.secrets.publicKey);
  };

  const handleRemove = () => {
    // Implement remove logic here
    console.log('PublicKey removed');
  };

  return (
    <Box>
      <FormControlLabel
        control={
        <Switch
          name="useCustomKey"
          checked={useCustomKey}
          onChange={handleToggleChange}
        />
      }
        label="Use Custom Encryption Key"
      />
      {useCustomKey && (
        <>
          <Typography variant="body2">Enter your public key to use custom encryption:</Typography>
          
          <RsaKeyInstructions />
          <TextField
            fullWidth
            margin="normal"
            label="Public Key"
            name="publicKey"
            value={publicKey}
            rows={4}
            multiline
            onChange={(e) => setPublicKey(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            name="keyName"
            value={keyName}
            onChange={(e) => setKeyName(e.target.value)}
          />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
            {publicKey && (
              <>
                <Button variant="outlined" color="secondary" onClick={handleUpdate}>
                  Update
                </Button>
                <Button variant="outlined" color="error" onClick={handleRemove}>
                  Remove
                </Button>
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}
export default EncryptionKeyToggle