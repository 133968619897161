import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Container, Grid } from '@mui/material';
import CharitiesLedger from './CharitiesLedger';
import Charities from './Charities';
import axios from 'axios';
import DigitalVault from '../common/digitalVault/DigitalVault';

import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
// Import other necessary components and services

const category = "charities"
const CharitiesComponent = () => {
    const [documents, setDocuments] = useState([]);
    const axiosWithCredentials = useAxiosWithCredentials()
  const loadFiles = async () => {
    try {
      // Replace this URL with your actual endpoint
      const response = await axiosWithCredentials.get('/files/'+category+"/");
      console.log(JSON.stringify(response))
      setDocuments(response.data); // Assuming the response contains your files
    // , isLoading: false, downloadUrl: null
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
  };

  useEffect(() => {
    loadFiles();
  }, []); 
  
  
  const handleUpload = async (file) => {
  try {
    // Step 1: Request a pre-signed URL from the Lambda function
    const response = await axiosWithCredentials.post('/files/upload/'+category, {
      fileName: file.name,
      fileType: file.type
    });

    const { uploadUrl } = response.data;
    console.log(uploadUrl)
    // Step 2: Upload the file to S3 using the pre-signed URL
    const s3Response = await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    });

    if (s3Response.status === 200) {
      console.log('File upload successful');
      loadFiles();
    } else {
      console.error('File upload failed');
      // Handle the upload failure (e.g., display error message)
    }
  } catch (error) {
    console.error('Error during file upload', error);
    // Handle any errors that occurred during the upload process
  }
};

const handleDelete = async (documentId) => {
  console.log("Delete function triggered for document ID:", documentId);
  try {
    // Step 1: Request a pre-signed URL from the Lambda function
    const response = await axiosWithCredentials.put('/files/delete', {
      fileKey: documentId
    });

    if (response.status === 200 || response.status === 201) {
      console.log('File delete successful');
      loadFiles();
    } else {
      console.error('File delete failed');
      // Handle the upload failure (e.g., display error message)
    }
  } catch (error) {
    console.error('Error during file delete', error);
    // Handle any errors that occurred during the upload process
  }
};

const handleDownload = (document) => {
  console.log("Download function triggered for document:", document);
  // Implement download logic here
};
    return (
        <div>
                <Grid container spacing={10}>
                    {/* Main Grid to layout the page */}
                    <Grid item xs={12} md={12}>
                        {/* Sidebar components like AccountList, Search, Filters */}
                        <Charities />
                        {/* Other Sidebar Components */}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {/* Main Content Area */}
                        <CharitiesLedger />
                        {/* Other content components like UserSettings */}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {/* Main Content Area 
                        */}
                        <DigitalVault
                          label="Charitable Donations"
                          category="charities"
                        />
                        {/* Other content components like UserSettings */}
                    </Grid>
                </Grid>
        </div>
    );
};

export default CharitiesComponent;
