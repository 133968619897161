import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const CharitiesLedger = () => {
  const [donations, setDonations] = useState([]);
  const [currentDonation, setCurrentDonation] = useState({ charityName: '', amount: '', date: '', notes: '' });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = (donation = {}) => {
    setCurrentDonation(donation);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleChange = (e) => {
    setCurrentDonation({ ...currentDonation, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (currentDonation.id) {
      setDonations(donations.map(d => d.id === currentDonation.id ? currentDonation : d));
    } else {
      setDonations([...donations, { ...currentDonation, id: Date.now() }]);
    }
    handleCloseDialog();
  };

  const handleDelete = (id) => {
    setDonations(donations.filter(d => d.id !== id));
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
        Add Donation
      </Button>
      <TableContainer component={Paper} style={{ maxHeight: 400 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Charity Name</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {donations.map((donation) => (
              <TableRow key={donation.id}>
                <TableCell component="th" scope="row">
                  {donation.charityName}
                </TableCell>
                <TableCell align="right">{`$${donation.amount}`}</TableCell>
                <TableCell align="right">{donation.date}</TableCell>
                <TableCell>{donation.notes}</TableCell>
                <TableCell>
                  <Button onClick={() => handleOpenDialog(donation)}>Edit</Button>
                  <Button onClick={() => handleDelete(donation.id)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{currentDonation.id ? 'Edit Donation' : 'New Donation'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Charity Name"
            name="charityName"
            value={currentDonation.charityName}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Amount"
            name="amount"
            value={currentDonation.amount}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date"
            name="date"
            type="date"
            value={currentDonation.date}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Notes"
            name="notes"
            value={currentDonation.notes}
            onChange={handleChange}
            fullWidth
            multiline
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CharitiesLedger;
