import React, { useState, useEffect, useRef } from 'react';
import { Card, Icon, Button, HStack, Stack, Text, Modal } from '@arcault-ui/shared-components';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import { useNavigate } from 'react-router-dom';

import DependantInfo from '../common/DependantInfo';
import NextStep from '../common/NextStep';


const FormRenderer = ({ formData, isOpen, setIsOpen }) => {
  const { form, props } = formData;

  const formComponents = {
    'add-dependent': (
      <Modal title="Add Dependent" isOpen={isOpen}>
        <DependantInfo {...props} />
        <HStack variant="end">
          <Button type="primary" onClick={() => setIsOpen(false)}>Done</Button>
        </HStack>
      </Modal>
     )
  };

  return (
    <>
      {formComponents[form] || <></>}
    </>
  );
};

const EstateNextStep = ({ dashboard, priority, setProgress, setStage, loadAssociatedData }) => {
    const [nextStep, setNextStep] = useState();
    const [formOpen, setFormOpen] = useState(false);

    const loaderRef = useRef({});
    
    const axiosWithCredentials = useAxiosWithCredentials();
    const navigate = useNavigate();

      useEffect(() => {
        const getUserJourney = async () => {
          try {
            const nextData = await axiosWithCredentials.get('/ajourney/datagent/estate_planning');
            console.log('nextDataxx', nextData.data);
            setNextStep(nextData.data);
            setProgress(nextData.data?.progress * 100)
            setStage(nextData?.data?.stepId)
            
          } catch (error) {
            console.error("Failed to load user journey:", error);
          }
        }
        if (!loaderRef?.loaded) {
          getUserJourney();
          loaderRef.loaded = true;
        }
        
      }, [axiosWithCredentials, setProgress, loaderRef]);

      const saveData = async (propertyValue) => {
        const payload = {
          stepId: nextStep.stepId,
          propertyName: nextStep.propertyName,
          propertyValue
        };

        const savedData = await axiosWithCredentials.post('/ajourney/datagent/estate_planning', payload);
        if (setProgress) setProgress(savedData.data?.progress * 100)
        loadAssociatedData();
        setNextStep(savedData.data);

        return savedData;
      }

      return (
        <Stack variant="end">
          {/*<Text variant="h2">Estate Planning</Text>*/}
          <Card className={`estate-next-step ${dashboard ? priority ? 'priority-card-dashbord' : 'dashboard-card' : 'card-mint-highlight'}`} title={dashboard ? 'Estate Planning' : (nextStep?.stepId === 'stage_complete' ? 'Congratulations!' : 'Next Step')} footer={false && (
            <HStack variant="spread">
              {dashboard && <>&nbsp;</>}
              {dashboard && <Button type="primary" onClick={() => navigate('/estate')}>Estate Planning Dashboard</Button>}
              {!dashboard && nextStep?.page && <Button type="outline" onClick={() => navigate(nextStep?.page)}>{nextStep?.pageName}</Button>}
              {!nextStep?.page && <>&nbsp;</> && !dashboard && 
              <Button type="primary" onClick={() => setFormOpen(true)}>{'Open Form'}</Button>}
            </HStack>
          )}>
            {nextStep && (
              <FormRenderer formData={nextStep} isOpen={formOpen} setIsOpen={setFormOpen}/>
            )}
            {nextStep && nextStep?.stepId !== 'stage_complete' && <NextStep nextStep={nextStep} setNextStep={setNextStep} saveData={saveData} />}
            {nextStep && nextStep?.stepId === 'stage_complete' && (
              <div id={`step-complete`} className="step-container">
                <Stack variant="center" className="estate-next-step-inner">
                  <Text variant="h4"><Icon name="celebration" /> Let's find you an Estate Attorney.</Text>
                </Stack>
              </div>
            )}

          </Card>
        </Stack>
      );
    };
    
    export default EstateNextStep;
