import React, { useState, useEffect } from 'react';
import { signUp, confirmSignUp } from '@aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Link,
} from '@mui/material';

const SignUp = ({ onSignInClick }) => {
  const [formState, setFormState] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    code: '',
  });
  const [message, setMessage] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [confirmationStage, setConfirmationStage] = useState(1); // 1: SignUp, 2: Message, 3: Enter Code
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const emailFromURL = searchParams.get('email');
    const codeFromURL = searchParams.get('code');
    const idFromURL = searchParams.get('uid');
    
    console.log(emailFromURL)
    console.log(codeFromURL)
    console.log(idFromURL)
    
    if (emailFromURL && codeFromURL && idFromURL) {
      setFormState({ ...formState, email: emailFromURL, code: codeFromURL, uid: idFromURL});
      setConfirmationStage(3);
    }
  }, [searchParams]);

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null);
    setError(null);
    try {
      const username = uuidv4();
      
      await signUp({
        username: username,
        password: formState.password,
        options: {
          userAttributes: {
            email: formState.email,
            given_name: formState.firstName,
            family_name: formState.lastName,
          }, 
          clientMetadata: {
            app: "lifeplan",
            appVersion: process.env.REACT_APP_VERSION,
            appSource: process.env.REACT_APP_SOURCE,
            appUrl: process.env.REACT_APP_URL
          }
        }
      });
      setIsSubmitted(true);
      setMessage('Sign-up successful. Please check your email for verification.');
      setConfirmationStage(2); // Show the confirmation message
    } catch (err) {
      setError(err.message || 'Failed to sign up. Please try again.');
    }
  };

  const handleConfirmSignUp = async (e) => {
    e.preventDefault();
    setMessage(null);
    setError(null);
    try {
      console.log(formState.uid)
      console.log(formState.code)
      await confirmSignUp({username: formState.uid, confirmationCode: formState.code});
      setMessage('Confirmation successful! You can now sign in.');
      navigate('/signIn', { state: { message: 'Confirmation successful! You can now sign in.' } })
      
    } catch (err) {
      setError(err.message || 'Failed to confirm. Please try again.');
    }
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 8 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
          p: 4,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Arcault
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {confirmationStage === 1 ? 'Create Your Account' : 'Confirm Your Email'}
        </Typography>

        {confirmationStage === 1 && (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <TextField
              margin="normal"
              fullWidth
              label="First Name"
              name="firstName"
              value={formState.firstName}
              onChange={handleChange}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Last Name"
              name="lastName"
              value={formState.lastName}
              onChange={handleChange}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formState.email}
              onChange={handleChange}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Password"
              name="password"
              type="password"
              value={formState.password}
              onChange={handleChange}
              required
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 1 }}>
              Sign Up
            </Button>
          </form>
        )}

        {confirmationStage === 2 && (
          <Typography variant="subtitle1">
            A confirmation code has been sent to {formState.email}. Please check your inbox.
          </Typography>
        )}

        {(confirmationStage === 3)  && (
          <form onSubmit={handleConfirmSignUp} style={{ width: '100%' }}>
            <TextField
              margin="normal"
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formState.email}
              onChange={handleChange}
              required
              disabled // Keep the email disabled since it's prefilled from URL
            />
            <TextField
              margin="normal"
              fullWidth
              label="Confirmation Code"
              name="code"
              value={formState.code}
              onChange={handleChange}
              required
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 1 }}>
              Confirm
            </Button>
          </form>
        )}

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {message && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {message}
          </Alert>
        )}

        {confirmationStage === 1 && (
          <Typography variant="body2" sx={{ mt: 2 }}>
            Already have an account?{' '}
            <Link onClick={() => navigate('/signin')} sx={{ cursor: 'pointer' }}>
              Sign in here
            </Link>
          </Typography>
        )}
      </Box>
      <Box mt={4} textAlign="center">
        <Typography variant="body2" color="textSecondary">
          &copy; 2024 Arcault
        </Typography>
        <Link href="#" variant="body2">
          Privacy Policy
        </Link>{' '}
        |{' '}
        <Link href="#" variant="body2">
          Terms of Service
        </Link>
      </Box>
    </Container>
  );
};

export default SignUp;
