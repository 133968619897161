import React, { useState } from 'react';
import { TextField, Button, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const Education = () => {
  const [educationList, setEducationList] = useState([]);
  const [currentEducation, setCurrentEducation] = useState({ schoolName: '', degree: '', fieldOfStudy: '', startDate: '', endDate: '', notes: '' });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = (education = {}) => {
    setCurrentEducation(education);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleChange = (e) => {
    setCurrentEducation({ ...currentEducation, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (currentEducation.id) {
      setEducationList(educationList.map(edu => edu.id === currentEducation.id ? currentEducation : edu));
    } else {
      setEducationList([...educationList, { ...currentEducation, id: Date.now() }]);
    }
    handleCloseDialog();
  };

  const handleDelete = (id) => {
    setEducationList(educationList.filter(edu => edu.id !== id));
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
        Add Education
      </Button>
      <List>
        {educationList.map((education) => (
          <ListItem key={education.id}>
            <ListItemText primary={education.schoolName} secondary={`${education.degree}, ${education.fieldOfStudy}`} />
            <Button onClick={() => handleOpenDialog(education)}>Edit</Button>
            <Button onClick={() => handleDelete(education.id)}>Delete</Button>
          </ListItem>
        ))}
      </List>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{currentEducation.id ? 'Edit Education' : 'Add Education'}</DialogTitle>
        <DialogContent>
          <TextField
            label="School Name"
            name="schoolName"
            value={currentEducation.schoolName}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Degree"
            name="degree"
            value={currentEducation.degree}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Field of Study"
            name="fieldOfStudy"
            value={currentEducation.fieldOfStudy}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Start Date"
            name="startDate"
            type="date"
            value={currentEducation.startDate}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date"
            name="endDate"
            type="date"
            value={currentEducation.endDate}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Notes"
            name="notes"
            value={currentEducation.notes}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Education;
