import React, { useState } from 'react';
import { Box, TextField, Button, List, ListItem, ListItemText, Typography } from '@mui/material';

// Example log data
const logs = [
  { id: 1, message: 'User initiated an estate plan workflow', timestamp: '2024-03-31 10:45' },
  { id: 2, message: 'User updated their profile', timestamp: '2024-03-30 15:30' },
  // Add more logs here
];

function LogComponent() {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredLogs = logs.filter(log => log.message.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <Box sx={{ margin: '20px 0' }}>
      <Typography variant="h6">Interaction Log</Typography>
      <TextField
        label="Search Logs"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        margin="normal"
        fullWidth
      />
      <List sx={{ maxHeight: '300px', overflow: 'auto' }}>
        {filteredLogs.map(log => (
          <ListItem key={log.id}>
            <ListItemText primary={log.message} secondary={log.timestamp} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default LogComponent;
