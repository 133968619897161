import React, { useState, useEffect } from 'react';
import axiosWithCredentials from '../hooks/useAxiosWithCredentials';
import JsonEditor from './JsonEditor';
import Filter from './Filter';
import { Box } from '@chakra-ui/react';
import { Autocomplete, SearchBar, Card, HStack, Icon, Stack, Text, Button } from '@arcault-ui/shared-components';
import Drawer from './Layout/Drawer';
import './JsonEditorPage.css';

const JsonEditorPage = () => {
  const [journeyType, setJourneyType] = useState('welcome');
  const [jsonData, setJsonData] = useState({});
  const [filter, setFilter] = useState({});
  const [expandedAll, setExpandedAll] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const useAxiosWithCredentials = axiosWithCredentials();
  const [searchValue, setSearchValue] = useState('');
  const [journeyValue, setJourneyValue] = useState('welcome');


  // https://api.dev.arcault.com/ajourney/model/estate_planning

    const onSave = async () => {
        const response = await useAxiosWithCredentials.put(`https://api.dev.arcault.com/ajourney/model/${journeyType}`, jsonData);
    }
  const fetchJsonData = async (journeyType) => {
    try {
      const response = await useAxiosWithCredentials.get(`https://api.dev.arcault.com/ajourney/model/${journeyType}`);
      /*const response = { data: {
        title: 'Welcome',
        description: 'This is the welcome journey',
        steps: [
            {
                title: 'step1',
                description: 'description',
                subStep: [
                    { id: 'substep1' },
                ]
            },
            {
                title: 'step2',
                description: 'description2',
            }

        ]
      }}*/
      console.log('response', response);
      setJsonData(response.data);
    } catch (error) {
      console.error("Failed to load JSON data:", error);
    }
  };

  useEffect(() => {
    if (journeyType) {
        fetchJsonData(journeyType);
    }
  }, [journeyType])



const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
        setJourneyType(journeyValue);
    }
};
  const handleJourneyTypeChange = (e) => {
    setJourneyValue(e.target.value);
  };

  const toggleExpandAll = () => {
    setExpandedAll((prev) => !prev);
  };

  return (
<>
<Drawer isOpen={isFilterOpen}>
    <Button className="drawer-close" onClick={() => setIsFilterOpen(false)}><Icon name="close" /></Button>
    <Filter jsonData={jsonData} filter={filter} setFilter={setFilter} expandedAll={expandedAll} />
</Drawer>
    <Card className="json-editor" footer={(
            <Button type="primary" onClick={onSave}>Save</Button>
    )}>
      <Stack direction="row" spacing={4}>
        <Box flex="1" style={{ /*padding: 16,*/ height: '100%', width: 1000 }}>
          <Stack className="top-filter" variant="center">
          <HStack variant="center">
            <Autocomplete value={journeyType} onChange={handleJourneyTypeChange}
                suggestions={[
                    'welcome', 'estateplanning',
                ]}
                onKeyDown={handleKeyPress}
            />
            <Button className="expand-collapse-toggle" onClick={toggleExpandAll}>
              {expandedAll ? 'Collapse All' : 'Expand All'}
            </Button>
            <Button onClick={() => setIsFilterOpen(!isFilterOpen)}>
              View Filter
            </Button>
            {/*<SearchBar placeholder="Doesn't work yet" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />*/}

            </HStack>
          </Stack>
            <div style={{ paddingBottom: 16 }}>
              <Text variant="h2">{jsonData.title}</Text>
            </div>
            <JsonEditor expandedAll={expandedAll} searchValue={searchValue} jsonData={jsonData} filter={filter} setJsonData={setJsonData} />
        </Box>
      </Stack>
    </Card>
    </>     
  );
};

export default JsonEditorPage;