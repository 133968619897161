import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Grid, Paper } from '@mui/material';
import DocumentsTable2 from '../common/DocumentsTable';
import TaxChecklist from './TaxChecklist'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tax-tabpanel-${index}`}
      aria-labelledby={`tax-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Grid container spacing={2}>
            {children}
          </Grid>
        </Box>
      )}
    </div>
  );
}



  


const TaxRecords = () => {

    
  const [selectedTab, setSelectedTab] = useState(0);
  const taxYears = ['2023', '2022', '2021']; // Example years

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={selectedTab} onChange={handleChange} aria-label="Tax Year Tabs">
        {taxYears.map((year, index) => (
          <Tab label={year} id={`tax-tab-${index}`} aria-controls={`tax-tabpanel-${index}`} key={year} />
        ))}
      </Tabs>
      {taxYears.map((year, index) => (
        <TabPanel value={selectedTab} index={index} key={year}>
          {/* Replace this part with your grid content */}
          
            <Grid item xs={6}>
              <Paper elevation={3} style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {/* Content for each grid item */}
                Item 
              </Paper>
            </Grid>
            <Grid item xs={6}>
                {/* Content for each grid item */}
                <TaxChecklist />
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={3} style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {/* Content for each grid item */}
                Item 
              </Paper>
            </Grid>
            <Grid item xs={12}>
                {/* Content for each grid item */}
                <DocumentsTable2
                  label={'Tax Records '+  taxYears[selectedTab]}
                  category={'tax_'+  taxYears[selectedTab]}
                />
            </Grid>
          
        </TabPanel>
      ))}
    </Box>
  );
};

export default TaxRecords;
