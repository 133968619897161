import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';

const OnlineAccounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editAccount, setEditAccount] = useState(null);

  // Fetch accounts from API or local storage (replace with your implementation)
  useEffect(() => {
    // fetchAccounts()
  }, []);

  const handleAddAccount = () => {
    setOpenEditDialog(true);
    setEditAccount(null);
  };

  const handleEditAccount = (account) => {
    setOpenEditDialog(true);
    setEditAccount(account);
  };

  const handleDeleteAccount = (account) => {
    // deleteAccount(account)
    setAccounts(accounts.filter((a) => a.id !== account.id));
  };

  const handleSaveAccount = () => {
    // saveAccount(editAccount)
    setAccounts((prevAccounts) => [...prevAccounts, editAccount]);
    setOpenEditDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenEditDialog(false);
  };

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Platform</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map((account) => (
              <TableRow key={account.id}>
                <TableCell>{account.platform}</TableCell>
                <TableCell>{account.username}</TableCell>
                <TableCell>{account.url}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEditAccount(account)}>Edit</Button>
                  <Button onClick={() => handleDeleteAccount(account)}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="contained" onClick={handleAddAccount}>
        Add Account
      </Button>
      <Dialog open={openEditDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {editAccount ? 'Edit Account' : 'Add Account'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              label="Platform"
              value={editAccount?.platform || ''}
              onChange={(e) => setEditAccount({ ...editAccount, platform: e.target.value })}
            />
            <TextField
              label="Username"
              value={editAccount?.username || ''}
              onChange={(e) => setEditAccount({ ...editAccount, username: e.target.value })}
            />
            <TextField
              label="URL"
              value={editAccount?.url || ''}
              onChange={(e) => setEditAccount({ ...editAccount, url: e.target.value })}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSaveAccount}>Save</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default OnlineAccounts;