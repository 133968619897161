import React, { useState } from 'react';
import {Grid, Card, CardContent, CardActions, Button, Typography, CardMedia } from '@mui/material';
import ImageUpload from '../common/ImageUpload'
import IdentificationInfoComponent from './IdentificationInfoComponent'; // Import your component

const Identification = () => {
  const [uploads, setUploads] = useState({
    license: null,
    passport: null,
    birthCertificate: null
  });

  const handleFileChange = (type) => (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploads({ ...uploads, [type]: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const idTypes = [
    { name: 'License', type: 'license' , imageLabels: ['front', 'back'], 
    keyFields:[{label: "Name", value: "John Miller"}, {label: "Licence Number", value: "S2387238432423"},
    {label: "iss", value: "09/25/2023"}, {label: "Exp", value: "05/27/2028"}]},
    { name: 'Passport', type: 'passport', imageLabels: ['front'],keyFields:[] },
    { name: 'Birth Certificate', type: 'birthCertificate' , imageLabels: ['front', 'back'],keyFields:[]}
  ];

  return (
    <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
      {idTypes.map(({ name, type, imageLabels, keyFields }) => (
        
            <Card>
              <CardContent>
                <Typography variant="h6" component="h2">
                  {name}
                </Typography>
                <ImageUpload category={type} imageLabels={imageLabels} keyFields={keyFields} />
              </CardContent>
              <CardActions>
                {/* Actions if any */}
              </CardActions>
            </Card>
      ))}
      </Grid>
          <Grid item xs={12} md={4}>
            <IdentificationInfoComponent />
          </Grid>
    </Grid>
  );
};

export default Identification;
