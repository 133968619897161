import React, { useEffect, useState } from 'react';

import { Card, Text, Button, HStack, Stack } from '@arcault-ui/shared-components';
import FormDialog from '../common/infoAssets/FormDialog';
import './EstateValue.css'; // Assuming you have a CSS file for additional styles

const EstateValueCard = ({ navigate, assets, liabilities }) => {
  const [estateValue, setEstateValue] = useState(null);
  const [largestAssets, setLargestAssets] = useState([]);
  const [largestLiabilities, setLargestLiabilities] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [editMode, setEditMode] = useState(false);


  const formattedValue = (value) => {
    let guaranteedValue = value || 0;
    if (guaranteedValue >= 1000000) {
        return `$${(guaranteedValue / 1000000).toFixed(1)} million`;
    } else if (guaranteedValue >= 10000) {
        return `$${(guaranteedValue / 1000).toFixed(1)}k`;
    } else {
        return `$${guaranteedValue}`;
    }
  };
  const hasValue = Boolean(estateValue);


  useEffect(() => {
    if (assets && liabilities) {
        let totalAssets = 0;
        assets.forEach(asset => {
            totalAssets += parseInt(asset?.estimatedValue || 0);
        });
        const largestAssetsList = assets
            .sort((a, b) => b.estimatedValue - a.estimatedValue)
            .slice(0, 3);

        const totalLiabilities = liabilities.reduce((sum, liability) => sum + liability?.estimatedValue || 0, 0);
        const largestLiabilitiesList = liabilities
            .sort((a, b) => b.estimatedValue - a.estimatedValue)
            .slice(0, 3);

        const estimatedValue = totalAssets - totalLiabilities;
        
        setEstateValue(estimatedValue);
        setLargestAssets(largestAssetsList);
        setLargestLiabilities(largestLiabilitiesList);
    }
  }, [assets, liabilities]);

    const handleClose = () => {
        setFormOpen(false);
        setFormData({});
    };

    const fields = [
        { id: 'description', label: 'Description',column: true, form: true},
        {id: 'estimatedValue', label: 'Estimated Value',column: true, form: true}
      ];

      

      const handleSave = async () => {
        /*if (editMode) {

            try {
              const response = await axiosWithCredentials.put(`/fo/${service}/info/${formData.id}`, formData);
              if (response.status === 200 || response.status === 201) {
                console.log('Update successful');
                fetchAssets();
              } else {
                console.error('Update failed');
                // Handle the upload failure (e.g., display error message)
              }
            } catch (error) {
              console.error('Error during Update', error);
              // Handle any errors that occurred during the upload process
            }
        } else {
            try {
              const response = await axiosWithCredentials.post(`/fo/${service}/info`, formData);
              if (response.status === 200 || response.status === 201) {
                console.log('Create successful');
                fetchAssets();
              } else {
                console.error('Create failed');
                // Handle the upload failure (e.g., display error message)
              }
            } catch (error) {
              console.error('Error during Create', error);
              // Handle any errors that occurred during the upload process
            }
        }*/
        handleClose();
    };
      const handleFormChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
      };

  return (
    <Card className="estate-value-card your-vault-card" footer={(
        <HStack variant="end">
            <Button type="outline" onClick={() => navigate('/profile/assets')}>View all</Button>
        </HStack>
    )}>
        <FormDialog
            open={formOpen}
            handleClose={handleClose}
            editMode={editMode}
            dialogTitle={"Asset"}
            fields={fields}
            formData={formData}
            handleFormChange={handleFormChange}
            handleSave={handleSave}
        />
        <Stack variant="center">
            <div className="estate-value-inner">
                <Text variant="h2">Estate Value</Text>
                <Text variant="h1" className={`value ${hasValue ? 'has-value' : 'no-value'}`}>
                    <span data-testid="estate-value">{formattedValue(estateValue)}</span>
                </Text>
                {hasValue && (
                    <div className="description">
                        <Text variant="body">
                            Based on <span data-testid="assets-total">{assets.length}</span> asset{assets.length > 1 || assets.length === 0 ? 's' : ''} and <span data-testid="liabilities-total">{liabilities.length}</span> liabilit{liabilities.length > 1 || liabilities.length === 0 ? 'ies' : 'y'}
                            {largestAssets && largestAssets.length > 0 || largestLiabilities & largestLiabilities.length > 0 && `, including your {' '}
                            ${largestAssets.map((asset, index) => (
                                <span key={index} className="asset">
                                {asset.assetType}

                                {index < largestAssets.slice(0, 3).length - 1 ? ', ' : ''}
                                {index === largestAssets.length - 2 && ' and '}
                                </span>
                            ))}
                            ${liabilities.length > 0 && assets.length > 0 && ', and '}
                            ${largestLiabilities.slice(0, 3).map((liability, index) => (
                                <span key={index} className="liability">
                                {liability.assetType}
                                {index < largestLiabilities.slice(0, 3).length - 1 ? ', ' : ''}
                                {index === largestLiabilities.length - 2 && ' and '}
                                </span>
                            ))}. `}
                            {/*<span onClick={() => setFormOpen(true)} className="edit">Add asset.</span>*/}
                        </Text>
                    </div>
                )}
            </div>
        </Stack>
    </Card>
  );
};

export default EstateValueCard;