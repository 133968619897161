import React, { useEffect, createContext, useState, useContext } from 'react';

const HelpContext = createContext();

export const HelpProvider = ({ children }) => {
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [helpPrompt, setHelpPrompt] = useState("");

  useEffect(() => {
    if (helpPrompt) {
      setIsHelpOpen(true);
    }
  }, [helpPrompt]);

  useEffect(() => {
    if (!isHelpOpen && helpPrompt) {
      setHelpPrompt("");
    }
  }, [isHelpOpen]);

  return (
    <HelpContext.Provider value={{ isHelpOpen, setIsHelpOpen, helpPrompt, setHelpPrompt }}>
      {children}
    </HelpContext.Provider>
  );
};

export const useHelp = () => useContext(HelpContext);