import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography, IconButton, Collapse } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import PeopleAssignment from '../common/PeopleAssignment';

const EstateExecutorForm = () => {
  const [executorForm, setExecutorForm] = useState({
    executorContactId: '', 
    executorLOIText: '', 
    executorLOIFile: null
  });
  const [contacts, setContacts] = useState([]);
  const [showCoExecutor, setShowCoExecutor] = useState(false);
  const [infoExpanded, setInfoExpanded] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const axiosWithCredentials = useAxiosWithCredentials();
  
 
    
  useEffect(() => {
    const loadData = async () => {
      const fetchedContacts = await axiosWithCredentials.get('/fo/contacts');
      const fetchedExecutorData = await axiosWithCredentials.get('/fo/estate/executor/primary');
      setContacts(fetchedContacts.data);
      setExecutorForm(fetchedExecutorData.data);
      if(executorForm?.coexecutorContactId){
        setShowCoExecutor(true)
      }
    };
    loadData();
  }, []);

  const handleChange = (event) => {
    setExecutorForm(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
    setIsChanged(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setExecutorForm(prev => ({
        ...prev,
        executorLOIFile: file
      }));
      setIsChanged(true);
    }
  };

  const handleSave = async () => {
    try{
      if(executorForm.contactId || executorForm.letterOfIntentText || executorForm.letterOfIntentFile){
        await axiosWithCredentials.put('/fo/estate/executor/primary', executorForm);
      }
      else{
        await axiosWithCredentials.post('/fo/estate/executor/primary', executorForm);
      }
      setIsChanged(false)
    }
    catch(err){
      console.log(err)
    }
  };

  const handleCancel = () => {
    // Reset changes
  };

  const toggleCoExecutor = async () => {
    if(showCoExecutor){
      setShowCoExecutor(false);
      setExecutorForm(prevState => ({
      ...prevState,
      coexecutorContactId: '',
      coexecutorLOIText: '',
      coexecutorLOIFile: null
    }));
    }
    else{
      setShowCoExecutor(true);
    }
  }
  const toggleInfoExpanded = () => setInfoExpanded(!infoExpanded);

  return (
    
    <Box sx={{ p: 4 }} style={{ backgroundColor: 'white' }}>
      <Typography variant="h4" gutterBottom>Executor</Typography>
      <Typography variant="h6" gutterBottom>
        Choosing an Executor
      </Typography>
      <Typography paragraph>
        The executor of your estate will manage the process from start to finish, including filing your will, 
        handling your assets, paying off debts, and distributing your property according to your wishes. 
        Choose someone who is willing, able, and impartial to ensure a smooth process.
      </Typography>
      <Typography gutterBottom>Once selected, your chosen executor will be notified via email and invited to confirm their acceptance.
      Please consider carefully before making your selection, and ensure that the individual you choose is aware of and prepared for the responsibilities involved in executing your wishes.</Typography>
      <PeopleAssignment label="Executor" category="estate" type="Executor" />

      <Typography variant="h6" gutterBottom>
        Co-Executors
      </Typography>
      <Typography paragraph>
        Appointing co-executors can help spread the responsibilities and might provide a balance of skills and proximity to the estate.
      </Typography>
      
      <PeopleAssignment label="Co-Executor" category="estate" type="coexecutor" toggleAddRemove={true} />
      
      <Typography variant="h6" gutterBottom>
        Professional Executors
      </Typography>
      <Typography paragraph>
        In cases where no suitable personal connection is available, or the estate is particularly complex, 
        it might be wise to appoint a professional such as a lawyer or a bank as the executor.
      </Typography>
      <Button
        variant="contained"
        component="label"
        sx={{ mt: 1, mb: 2 }}
      >
        Connect me with a Professional Executor
      </Button>
      <Box>
      <Button sx={{ mt: 1 }} onClick={toggleInfoExpanded}>{infoExpanded ? 'Hide Info' : 'Learn more about other considerations'}</Button>

      <Collapse in={infoExpanded}>
      
        <Typography variant="h6" gutterBottom>Emotional and Physical Capability</Typography>
        <Typography paragraph>Consider if your chosen executor is emotionally and physically capable of managing your estate, especially during a potentially stressful period.</Typography>
        <Typography variant="h6" gutterBottom>
        Financial Acumen
      </Typography>
      <Typography paragraph>
        Ensure the executor has the financial knowledge or support to manage complex estate issues, such as taxes and investments.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Location and Availability
      </Typography>
      <Typography paragraph>
        An executor who lives nearby or is readily available can more effectively manage estate affairs than someone who lives far away.
      </Typography>

      
      
      </Collapse>
      </Box>
    </Box>
  );
};

export default EstateExecutorForm;

