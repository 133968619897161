import React, { useState } from 'react';
import { Box, Typography, FormControlLabel, Checkbox, Button } from '@mui/material';

function SetupComponent() {
  const [isAuthorized, setIsAuthorized] = useState(false);

  const handleAuthorizationChange = (event) => {
    setIsAuthorized(event.target.checked);
  };

  const handleSubmit = () => {
    if (isAuthorized) {
      // Here, you would send a request to your backend to activate the service
      // For now, we'll just display an alert
      alert("You've successfully authorized messaging. Remember to save our number in your contacts!");
    } else {
      alert('Please authorize to proceed.');
    }
  };

  return (
    <Box sx={{ margin: '20px 0' }}>
      <Typography variant="h6" gutterBottom>
        Activate Assistant Messaging
      </Typography>
      <Typography variant="body1">
        Authorize us to interact with you via SMS for immediate assistance. You can text this number anytime to interact with your assistant.
      </Typography>
      <FormControlLabel
        control={<Checkbox checked={isAuthorized} onChange={handleAuthorizationChange} />}
        label="I authorize interaction via SMS and other messaging platforms"
      />
      <Box sx={{ marginTop: '20px' }}>
        <Button variant="contained" onClick={handleSubmit} disabled={!isAuthorized}>
          Activate
        </Button>
      </Box>
    </Box>
  );
}

export default SetupComponent;
