import React, { useState } from 'react';
import { Box, Typography, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import PeopleAssignment from '../common/PeopleAssignment';
const DurablePowerOfAttorneyForm = () => {

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Durable Power of Attorney
      </Typography>
      <Typography variant="h6" gutterBottom>
        Selecting a Durable Power of Attorney
      </Typography>
      <Typography paragraph>
        A Durable Power of Attorney is a legal document that grants someone authority to handle your financial and legal affairs if you are unable to do so. Unlike a standard Power of Attorney, it remains effective if you become incapacitated.
      </Typography>
      <PeopleAssignment label="Durable Power of Attorney" category="estate" type="dpoa"/>


      <Typography variant="h6" gutterBottom>
        Responsibilities of a Power of Attorney
      </Typography>
      <Typography paragraph>
        The appointed agent will have the authority to manage your finances, make legal decisions, and handle transactions such as paying bills, managing investments, and selling property. It's crucial to choose someone who is trustworthy and competent.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Choosing the Right Agent
      </Typography>
      <Typography paragraph>
        When selecting a Durable Power of Attorney, consider the person’s ability to manage complex financial matters and their understanding of your wishes and values. The person should be reliable, able to act under pressure, and maintain a high degree of integrity.
      </Typography>
    </Box>
  );
};

export default DurablePowerOfAttorneyForm;
