import React, { useState, useEffect } from 'react';
import { Checkbox, Stack, Text, Box, Collapse, Button } from '@chakra-ui/react';

const Filter = ({ jsonData, filter, setFilter }) => {
  const [expandedKeys, setExpandedKeys] = useState({});
  const [keys, setKeys] = useState({});

  useEffect(() => {
    const getKeys = (data, path = '') => {
      return Object.keys(data).reduce((acc, key) => {
        const currentPath = path ? `${path}.${key}` : key;
        const depth = currentPath.split('.').length - 1;
        const isArray = Array.isArray(data[key]);
        const displayKey = `${' '.repeat(depth * 2)}${key}${isArray ? ' (array)' : ''}`; // Use spaces for indentation and add "array" for arrays
  
        acc[currentPath] = { displayKey, isChecked: true };
  
        if (isArray && data[key].length > 0 && typeof data[key][0] === 'object') {
          Object.assign(acc, getKeys(data[key][0], currentPath));
        } else if (typeof data[key] === 'object' && data[key] !== null && !isArray) {
          Object.assign(acc, getKeys(data[key], currentPath));
        }
  
        return acc;
      }, {});
    };
  
    const keys = getKeys(jsonData);
    setKeys(keys);

    const newFilter = {};
    Object.keys(keys).forEach((k) => {
      console.log('keys', k);
      newFilter[k] = true;
    });
    setFilter(newFilter);

  }, [jsonData])

  const toggleExpand = (key) => {
    setExpandedKeys((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };



  const handleCheckboxChange = (key) => {
    console.log('key', key)
    setFilter((prev) => {
      const newFilter = { ...prev, [key]: !prev[key] };

      // If the key is part of an array, apply the filter to all items in the array
      const keyParts = key.split('.');
      const arrayKey = keyParts.slice(0, -1).join('.');
      if (Array.isArray(jsonData[arrayKey])) {
        jsonData[arrayKey].forEach((item, index) => {
          const itemKey = `${arrayKey}.${index}.${keyParts[keyParts.length - 1]}`;
          newFilter[itemKey] = newFilter[key];
        });
      }

      return newFilter;
    });
  };

  const handleShowOnly = (key) => {
    const keyParts = key.split('.');
    let currentKey = '';
    const newFilter = {};
    const matched = [];

    keyParts.forEach((part, index) => {
      currentKey = index === 0 ? part : `${currentKey}.${part}`;
      Object.keys(keys).forEach((k) => {
        console.log('keysx', k, currentKey);
        if (!matched.includes(k)) {
          newFilter[k] = k === currentKey;
          if (k === currentKey) {
            matched.push(k);
          }
        }
      });
    });

    console.log('newFilter', newFilter);
    
    setFilter(newFilter);
  };

  return (
    <Box height="100vh" overflowY="auto" padding={4} backgroundColor="#fff">
      <Text fontWeight="bold">Filter</Text>
      <Stack className="filter-body">
        {Object.keys(keys).map((key) => {
          const depth = key.split('.').length - 1;
          const isExpanded = expandedKeys[key];
          const isObject = typeof jsonData[key] === 'object' && jsonData[key] !== null;

          return (
            <Box key={key} marginLeft={`${depth * 20}px`}>
              <Stack direction="row" alignItems="center">
                {/*isObject ? (
                  <Button size="xs" onClick={() => toggleExpand(key)}>
                    {isExpanded ? '-' : '+'}
                  </Button>
                ) : (
                  <Box width="24px" /> // Placeholder for alignment
                )*/}
                {/*<Checkbox
                  isChecked={filter[key] !== false}
                  onChange={() => handleCheckboxChange(key)}
                  width="100%"
                >
                  {keys[key].displayKey}
                </Checkbox>*/}
                <div className={filter[key] ? 'shown-key' : 'hidden-key'}>
                {filter[key]}
                  <span>
                    {keys[key].displayKey} 
                    <span onClick={() => handleCheckboxChange(key)} className="hide-controls">{!filter[key] ? 'show' : 'hide'}</span>
                  </span>
                  <span onClick={() => handleShowOnly(key)} className="show-only-controls">show only</span>
                </div>
              </Stack>
              {isObject && !Array.isArray(jsonData[key]) && (
                <Collapse in={isExpanded}>
                  <Box marginLeft="20px">
                    {Object.keys(jsonData[key]).map((subKey) => (
                      <Checkbox
                        key={`${key}.${subKey}`}
                        isChecked={filter[`${key}.${subKey}`] !== false}
                        onChange={() => handleCheckboxChange(`${key}.${subKey}`)}
                        width="100%"
                      >
                        {`${' '.repeat((depth + 1) * 2)}${subKey}`}
                      </Checkbox>
                    ))}
                  </Box>
                </Collapse>
              )}
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default Filter;