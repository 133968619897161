import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  Collapse,
  FormControlLabel,
  Switch,
  Stack
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';

const PeopleAssignment = ({ category, type, label, toggleAddRemove }) => {
  const [formData, setFormData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [contacts, setContacts] = useState([]);
  const [showToggle, setShowToggle] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [uploadMode, setUploadMode] = useState(false); // True for upload file, false for write letter
  const axiosWithCredentials = useAxiosWithCredentials();

  useEffect(() => {
    const loadData = async () => {
      const fetchedContacts = await axiosWithCredentials.get('/fo/contacts');
      const fetchedData = await axiosWithCredentials.get(`/fo/${category}/${type}/primary`);
      setContacts(fetchedContacts.data);
      setFormData(fetchedData.data);
      setInitialData(fetchedData.data)
    };
    loadData();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setIsChanged(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        file: file
      }));
      setIsChanged(true);
    }
  };

  const handleSave = async () => {
    try {
      if (formData.partitionKey) {
        await axiosWithCredentials.put(`/fo/${category}/${type}/primary`, formData);
        if (initialData.primaryContactId) {
          const priorContact = contacts.find(contact => contact.id === initialData.primaryContactId);
          console.log(priorContact);
          if (priorContact) {
            await axiosWithCredentials.delete(`/fo/contacts/${priorContact.type}/${priorContact.id}/tag/${type}`);
          }
        }
      } else {
        await axiosWithCredentials.post(`/fo/${category}/${type}/primary`, formData);
      }

      const selectedContact = contacts.find(contact => contact.id === formData?.primaryContactId);
      if (selectedContact) {
        await axiosWithCredentials.post(`/fo/contacts/${selectedContact.type}/${selectedContact.id}/tag/${type}`);
      }
      setIsChanged(false);
    } catch (err) {
      console.error('Error handling save:', err);
    }
  };

  const handleCancel = () => {
    setFormData(initialData);
    setIsChanged(false)
  };

  return (
    <Box sx={{ p: 4 }}>
      {toggleAddRemove && (
        <Button sx={{ mt: 1 }} onClick={() => setShowToggle(!showToggle)}>
          {showToggle ? `Remove ${label}` : `Add ${label}`}
        </Button>
      )}

      <Collapse in={toggleAddRemove ? showToggle : true}>
        <FormControl fullWidth sx={{ mt: 2, mb: 3 }}>
          <InputLabel>{label}</InputLabel>
          <Select value={formData?.primaryContactId || ''} name="primaryContactId" label={label} onChange={handleChange}>
            {contacts.map((person) => (
              <MenuItem key={person.id} value={person.id}>{person.firstName} {person.lastName}</MenuItem>
            ))}
          </Select>
          <Typography variant="caption" sx={{ mt: 1 }}>
            Don't see the person you're looking for? Go to <a href={`/profile/contacts?returnUrl=${category}/${type}`}>Contacts</a> to add them, and you can return to this page.
          </Typography>
        </FormControl>

        {uploadMode ? (
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel sx={{ mt: 2 }}>Upload Letter of Intent</InputLabel>
            <input type="file" onChange={handleFileChange} />
            <Typography variant="caption" sx={{ mt: 4 }}>
              Upload a file containing the letter of intent.
            </Typography>
          </FormControl>
        ) : (
          <TextField
            label="Write Letter of Intent"
            name="primaryLOIText"
            multiline
            fullWidth
            rows={4}
            value={formData?.primaryLOIText}
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{
              shrink: formData?.primaryLOIText ? true : false, // Conditionally apply based on whether text is present
            }}
          />
        )}

          <Stack direction="row" spacing={1} alignItems="center">
        <Typography>Write</Typography>
        <Switch
              checked={uploadMode}
              onChange={() => setUploadMode(!uploadMode)}
              name="uploadMode"
            />
        <Typography>Upload</Typography>
      </Stack>
            

        <Button variant="contained" sx={{ mt: 1, mb: 2 }} onClick={handleSave} disabled={!isChanged}>
          Save
        </Button>
        <Button variant="outlined" sx={{ mt: 1, mb: 2, ml: 2 }} onClick={handleCancel} disabled={!isChanged}>
          Cancel
        </Button>
      </Collapse>
    </Box>
  );
};

export default PeopleAssignment;
