import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Box } from '@mui/system';
import { CardHeader } from '@mui/material';

const DoctorCard = ({ name, category, department, imageUrl }) => {
  const getInitials = (name) => {
    return name.split(' ').map((n) => n[0]).join('');
  };

  return (
    <Card sx={{ maxWidth: 345, m: 2 }}>
      <CardHeader
        avatar={
          <Avatar src={imageUrl} alt={`Dr. ${name}`}>
            {!imageUrl && getInitials(name)}
          </Avatar>
        }
        title={name}
        subheader={category}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          <Box fontWeight="fontWeightBold">Department:</Box> {department}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DoctorCard;
