import { useState, useEffect } from 'react';

const TYPE_MAP = {
    phone: {
        forceValue: (value) => {
            let formattedValue = value;
            formattedValue = formattedValue.replace(/\D/g, ''); // Remove non-numeric characters
            if (formattedValue.length > 3 && formattedValue.length <= 6) {
                formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(3)}`;
            } else if (formattedValue.length > 6) {
                formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(3, 6)}-${formattedValue.slice(6, 10)}`;
            }
            return formattedValue;
        },
        validation: (value) => {
            return value.replace(/\D/g, '').length === 10;
        }
    },
    email: {
        forceValue: (value) => value,
        validation: (value) => {
            return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
        }
    },
    date: {
        forceValue: (value) => value,
        validation: (value) => true,
        type: 'text'
    },
    date_backup: {
        forceValue: (value) => {
            let formattedValue = value.replace(/\D/g, ''); // Remove non-numeric characters
            if (formattedValue.length > 2 && formattedValue.length <= 4) {
              formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2)}`;
            } else if (formattedValue.length > 4) {
              formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2, 4)}/${formattedValue.slice(4, 8)}`;
            }
            return formattedValue;
          },
        validation: (value) => /^\d{2}\/\d{2}\/\d{4}$/.test(value),
    
    },
    secret: {
        type: 'password',
        forceValue: (value) => {
            let formattedValue = value.replace(/\D/g, ''); // Remove non-numeric characters
            if (formattedValue.length > 3 && formattedValue.length <= 5) {
              formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(3)}`;
            } else if (formattedValue.length > 5) {
              formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(3, 5)}-${formattedValue.slice(5, 9)}`;
            }
            return formattedValue;
        },
        validation: (value) => /^\d{3}-\d{2}-\d{4}$/.test(value),    
    }
}

/*{
    "stepId": "basic_info",
    "validator": {
        "regex": "^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$"
    },
    "description": "Your Social Security Number",
    "type": "secret",
    "propertyName": "socialSecurityNumber",
    "required": true
}*/

const useEnhancedInput = ({ initialValue, type }) => {
    const [value, setValue] = useState(initialValue);
    const [forcedValue, setForcedValue] = useState(initialValue);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        let formattedValue = value;
        let nowValid = false;
        if (!type || !TYPE_MAP[type]) { setForcedValue(value); setIsValid(true); console.log('valider'); return; }
        if (TYPE_MAP[type]) {
            formattedValue = TYPE_MAP[type].forceValue(value);
            nowValid = TYPE_MAP[type].validation(value);
            console.log('known type', type, formattedValue, nowValid);
        } else {
            nowValid = true;
        }

        setForcedValue(formattedValue);
        setIsValid(nowValid);
    }, [value]);

    return [value, setValue, forcedValue, isValid];
};

export default useEnhancedInput;