import { useState, useEffect, useMemo } from 'react';
import RelationshipInfo from '../common/RelationshipInfo';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials'
import { Grid, Container, Button, Box } from '@mui/material';


export default function Relationship() {
    
    const axiosWithCredentials = useAxiosWithCredentials()
    const [resetKey, setResetKey] = useState(0); // Initialize a key state
    const [formDataChanged, setFormDataChanged,] = useState(false);
    const [partnerData, setPartnerData] = useState({});
    const [initialFormData, setInitialFormData] = useState(null);
    
  const handleSave = async (event) => {
    event.preventDefault();
        try {
        if(partnerData.id){
          const response = await axiosWithCredentials.put(`/fo/contacts/${partnerData.type}/${partnerData.id}`, partnerData);
        }
        else{
          partnerData.tags = ['partner']
          const response = await axiosWithCredentials.post('/fo/contacts/family', partnerData);
        }
        
        await axiosWithCredentials.put(`/fo/user/basic/me`, );
        setFormDataChanged(false)
    } catch (err) {
      console.log(err);
    }
  };
  
const partnerDataChange = (event) => {
    const { name, value } = event.target;

    setPartnerData(prevPartnerData => ({
      ...prevPartnerData,
      [name]: value
    }));
    setFormDataChanged(
      true
      )
  };
  
  
  const handleReset = () => {
    console.log("resetting change")
    setPartnerData({ ...initialFormData });
    setFormDataChanged(
      false
      )
    setResetKey(prevKey => prevKey + 1); 
  };


  
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
      <form>
        {/* Your BasicInfo component */}
        {/* Assuming BasicInfo takes formData and updates it */}
        <RelationshipInfo  setInitialFormData={setInitialFormData} formData={partnerData} setFormData={setPartnerData} handleInputChange={partnerDataChange} />

        <Box mt={2} display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            color="primary"
            disabled={!formDataChanged}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            disabled={!formDataChanged}
            onClick={handleReset}
          >
            Cancel
          </Button>
        </Box>
      </form>
      </Grid>
    </Grid>
  );

}


