import React, { useState } from 'react';
import { Box, Typography, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import PeopleAssignment from '../common/PeopleAssignment';
const GuardianshipDesignationForm = () => {

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Guardianship Designations
      </Typography>
      <Typography variant="h6" gutterBottom>
        Choosing a Guardian
      </Typography>
      <Typography paragraph>
        A guardian is legally responsible for the care of your minor children or dependent adults if you are unable to care for them yourself. This role includes day-to-day care, as well as making decisions about health, education, and welfare.
      </Typography>
      <PeopleAssignment label="Primary Guardian" category="estate" type="guardianship"/>

      <Typography variant="h6" gutterBottom>
        Alternate Guardian
      </Typography>
      <Typography paragraph>
        It is wise to appoint an alternate guardian who can step in if the primary guardian is unable or unwilling to fulfill their role. This ensures continuity in care and decision-making for your dependents.
      </Typography>
      <PeopleAssignment label="Alternate Guardian" category="estate" type="alt-guardianship"/>

      <Typography variant="h6" gutterBottom>
        Considerations for Selection
      </Typography>
      <Typography paragraph>
        When choosing guardians, consider their ability to provide a stable and loving home, their values and parenting style, and how well they understand your own values and wishes for your children’s upbringing.
      </Typography>
    </Box>
  );
};

export default GuardianshipDesignationForm;
