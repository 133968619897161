import { useState, useEffect } from 'react';
import DigitalVault from '../common/digitalVault/DigitalVault';
import InfoAssetsTable from '../common/infoAssets/InfoAssetsTable';


const Banking = () => {
  


  
  
  const fields = [
    { id: 'name', label: 'Bank Name',column: "text", form: "text"},
    { id: 'type', label: 'Type',column: "text", form: "text"},
    {id: 'maskedAccount', label: 'Account',column: "masked", form: "masked"},
    { id: 'balance', label: 'Balance',column: "currency", form: "text"},
    { id: 'url', label: 'URL',column: "text"},
    
  ];
  
  

    // This is where you'll define your HealthPlan form structure
      return (
        <>
        
        <div>
      <InfoAssetsTable
        title="Bank Accounts"
        fields={fields}
        service="banking"
      />
    </div>
    
    <DigitalVault
      label="Banking"
      category="banking"
    />
  
    </>
  );
}
export default Banking
