import React, { useState } from 'react';
import { Typography, Checkbox, FormControlLabel, FormGroup, Paper } from '@mui/material';

const checklistItems = [
  { id: 1, label: 'Upload W-2s' },
  { id: 2, label: 'Upload 1099-DIV from Fidelity' },
  { id: 3, label: 'Upload 1099-DIV from USB' },
  { id: 4, label: 'Upload 1099-DIV from Etrade' },
  { id: 5, label: 'Upload 1095-A (Proof of Healthcare)' },
  { id: 6, label: 'Upload 1099' },
  { id: 7, label: 'Upload 1099' },
  // ... add other checklist items here
];

const TaxChecklist = () => {
  const [checkedState, setCheckedState] = useState(new Array(checklistItems.length).fill(false));

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  return (
    <Paper style={{ padding: '20px', margin: '20px' }}>
        <Typography variant="h4" gutterBottom>
        Tax Prep Checklist
      </Typography>
      <FormGroup>
        {checklistItems.map((item, index) => (
          <FormControlLabel
            key={item.id}
            control={
              <Checkbox
                checked={checkedState[index]}
                onChange={() => handleOnChange(index)}
                color="primary"
              />
            }
            label={item.label}
          />
        ))}
      </FormGroup>
    </Paper>
  );
};

export default TaxChecklist;
