
import React from 'react';
import { Box, Flex, Text, VStack } from '@chakra-ui/react'; 
import { NavLink } from 'react-router-dom';
import Icon from '../common/Icon.js'
import { Outlet, useLocation } from 'react-router-dom';
import Content from './Content'

// LeftNavMenu.jsx
const menuItems = [
  { url: "/", icon: "cottage", label: "Overview" },
  { url: "/assistant", icon: "assistant", label: "Personal Assistant" },
  { url: "/estate", icon: "history_edu", label: "Estate Planning" },
  { url: "/financial", icon: "account_balance", label: "Financial" },
  { url: "/insurance", icon: "health_and_safety", label: "Insurance" },
  { url: "/taxes", icon: "inventory", label: "Taxes" },
  { url: "/health", icon: "monitor_heart", label: "Health" },
];

const LeftNavMenu = ({ className }) => {
  return (
    <Flex
      direction="column"
      className={`navigation ${className}`}
    >
      <VStack spacing="2" align="stretch">
        {menuItems.map(({ url, icon, label }) => (
          <NavItem icon={icon} label={label} href={url} />
        ))}
      </VStack>
    </Flex>
  );
};

const NavItem = ({ icon, label, href }) => (
  <NavLink
    to={href}
    className="nav-item"
  >
    <Flex
      className="nav-item-inner"
    >
      <Icon name={icon} />
      <Text>{label}</Text>
    </Flex>
  </NavLink>
);

export default LeftNavMenu;