import React, { useState, useEffect } from 'react';
import { Checkbox, FormInput, Modal, Button, Select } from '@arcault-ui/shared-components';

const Row = ({ children }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
        {children}
    </div>
)


const ContactDialog = ({ open, onClose, handleSubmit, editableContact }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        type: null,
        birthday: '',
        sync: false
    });

    useEffect(() => {
        if (editableContact) setFormData(editableContact);
    }, [editableContact]);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

return (
    <Modal title={`${editableContact ? 'Edit' : 'Add New'} Contact`} isOpen={open} onClose={onClose}>
        <Row>
     <FormInput
        id="firstName"
        label="First Name"
        value={formData.firstName}
        onChange={handleChange}
        isRequired
      />
      <FormInput
        id="lastName"
        label="Last Name"
        value={formData.lastName}
        onChange={handleChange}
        isRequired
      />
      </Row>
      <FormInput
        id="email"
        label="Email"
        value={formData.email}
        onChange={handleChange}
        isRequired
      />
      <FormInput
        id="phone"
        label="Phone"
        value={formData.phone}
        onChange={handleChange}
        isRequired
      />
      <FormInput
        id="address"
        label="Primary Address"
        value={formData.address}
        onChange={handleChange}
      />
      <FormInput
        id="birthday"
        label="Birthday"
        type="date"
        value={formData.birthday}
        onChange={handleChange}
      />
      <div style={{ paddingBottom: 16, textAlign: 'right' }}>
        <Checkbox 
            name="sync" 
            
            label="Keep Contact info synced with user" 
            onChange={handleChange}
        />
    </div>
    <Select
        id="type"
        label="Type"
        value={formData.type}
        onChange={handleChange}
        options={[{
            value: 'family',
            label: 'Family'
        }, {
            value: 'friend',
            label: 'Friend'
        }, {
            value: 'professional',
            label: 'Professional'
        }]}
    />
    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <Button type="outline" onClick={onClose}>Cancel</Button>
        <Button type="primary" onClick={() => handleSubmit(formData)}>{`${editableContact ? 'Edit' : 'Save'}`}</Button>
    </div>
    </Modal>
  );
};
//defaultChecked={selectedContact.sync} 

export default ContactDialog;