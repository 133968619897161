import React, { useState } from 'react';
import { TextField, Button, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const MilitaryService = () => {
  const initialState = {
    branch: '',
    rank: '',
    serviceNumber: '',
    enlistmentDate: '',
    dischargeDate: '',
    dutyStations: '',
    deployments: '',
    awards: '',
    mos: '',
    training: '',
    injuries: '',
    notes: ''
  };
  const branches = ["Army", "Navy", "Air Force", "Marine Corps", "Coast Guard"];

  const [serviceRecords, setServiceRecords] = useState([]);
  const [currentRecord, setCurrentRecord] = useState(initialState);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  

  // Handlers for dialog, change, save, delete similar to previous components
const handleOpenDialog = (experience = {}) => {
    setCurrentRecord(experience);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleChange = (e) => {
    setCurrentRecord({ ...setCurrentRecord, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (currentRecord.id) {
      setServiceRecords(serviceRecords.map(exp => exp.id === currentRecord.id ? currentRecord : exp));
    } else {
      setServiceRecords([...serviceRecords, { ...currentRecord, id: Date.now() }]);
    }
    handleCloseDialog();
  };

  const handleDelete = (id) => {
    setServiceRecords(serviceRecords.filter(exp => exp.id !== id));
  };

  return (
    <div>
      <Button variant="contained" onClick={() => handleOpenDialog()}>Add Service Record</Button>
      <List>
        {serviceRecords.map(record => (
          <ListItem key={record.id}>
            <ListItemText primary={`${record.branch}, ${record.rank}`} secondary={`Service Number: ${record.serviceNumber}`} />
            <Button onClick={() => handleOpenDialog(record)}>Edit</Button>
            <Button onClick={() => handleDelete(record.id)}>Delete</Button>
          </ListItem>
        ))}
      </List>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>{currentRecord.id ? 'Edit Service Record' : 'Add Service Record'}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Branch of Service</InputLabel>
            <Select
              label="Branch of Service"
              name="branch"
              value={currentRecord.branch}
              onChange={handleChange}
            >
              {branches.map((branch, index) => (
                <MenuItem key={index} value={branch}>{branch}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Rank"
            name="rank"
            value={currentRecord.rank}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Service Number/ID"
            name="serviceNumber"
            value={currentRecord.serviceNumber}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Enlistment Date"
            name="enlistmentDate"
            type="date"
            value={currentRecord.enlistmentDate}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Discharge Date"
            name="dischargeDate"
            type="date"
            value={currentRecord.dischargeDate}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Duty Stations"
            name="dutyStations"
            value={currentRecord.dutyStations}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Deployments"
            name="deployments"
            value={currentRecord.deployments}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Awards and Decorations"
            name="awards"
            value={currentRecord.awards}
            onChange={handleChange}
            fullWidth
            multiline
            margin="normal"
          />
          <TextField
            label="Military Occupation Specialty (MOS)/Rate"
            name="mos"
            value={currentRecord.mos}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Training and Education"
            name="training"
            value={currentRecord.training}
            onChange={handleChange}
            fullWidth
            multiline
            margin="normal"
          />
          <TextField
            label="Service-Related Injuries"
            name="injuries"
            value={currentRecord.injuries}
            onChange={handleChange}
            fullWidth
            multiline
            margin="normal"
          />
          <TextField
            label="Notes"
            name="notes"
            value={currentRecord.notes}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MilitaryService;
