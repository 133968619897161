import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Container, Grid } from '@mui/material';
import AccountList from './DigitalList';
import AccountDetails from './DigitalDetails';
// Import other necessary components and services

const Digital = () => {
    // State and functions to handle different components and actions
    const [accounts, setAccounts] = useState([
  {
    id: 1,
    name: "Facebook",
    username: "john_doe",
    email: "johndoe@example.com"
  },
  {
    id: 2,
    name: "Twitter",
    username: "johndoe_tweets",
    email: "john@twitter.com"
  },
  {
    id: 3,
    name: "LinkedIn",
    username: "johnprofessional",
    email: "john.doe@linkedin.com"
  }
]);
    const [selectedAccount, setSelectedAccount] = useState(null);

    useEffect(() =>{
        setSelectedAccount(accounts[0]);
    }, [])
    
    const addAccount = (newAccount) => {
    setAccounts([...accounts, newAccount]);
    setSelectedAccount(newAccount); // Optional: Select the newly added account
  };

  const editAccount = (updatedAccount) => {
    const updatedAccounts = accounts.map(account => 
      account.id === updatedAccount.id ? updatedAccount : account
    );
    setAccounts(updatedAccounts);
  };

  const removeAccount = (accountId) => {
    const updatedAccounts = accounts.filter(account => account.id !== accountId);
    setAccounts(updatedAccounts);
    if (selectedAccount && selectedAccount.id === accountId) {
      setSelectedAccount(updatedAccounts[0] || null);
    }
  };
    
    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6">Digital Account Manager</Typography>
                    {/* Navigation Bar and other AppBar items */}
                </Toolbar>
            </AppBar>

            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    {/* Main Grid to layout the page */}
                    <Grid item xs={12} md={4}>
                        {/* Sidebar components like AccountList, Search, Filters */}
                        <AccountList 
                          accounts={accounts} 
                          onSelectAccount={setSelectedAccount} 
                          onAddAccount={addAccount} 
                          onEditAccount={editAccount} 
                          onRemoveAccount={removeAccount} 
                        />
                        {/* Other Sidebar Components */}
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {/* Main Content Area */}
                        {selectedAccount && 
                          <AccountDetails 
                            account={selectedAccount} 
                            onEditAccount={editAccount} 
                            onRemoveAccount={() => removeAccount(selectedAccount.id)}
                          />
                        }
                        {/* Other content components like UserSettings */}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Digital;
