// Drawer.js

import React from 'react';
import './Drawer.css'; // Create a CSS file for styling the drawer

const Drawer = ({ isOpen, children }) => {
  return (
    <div className={`drawer ${isOpen ? 'open' : ''}`}>
      {children}
    </div>
  );
};

export default Drawer;