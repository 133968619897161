import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Select, MenuItem, InputLabel, FormControl, FormGroup, FormControlLabel, Checkbox, Grid } from '@mui/material';
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';

const RequestForProposal = ({ userData, editMode }) => {
  const axiosWithCredentials = useAxiosWithCredentials();
  // State for the form fields
  const [formData, setFormData] = useState({
    fullName: userData?.fullName || '',
    email: userData?.email || '',
    phone: userData?.phone || '',
    location: userData?.location || '',
    estateValue: '',
    assets: '',
    beneficiaries: '',
    legalRequirements: '',
    objectives: '',
    previousEfforts: '',
    lawyerPreferences: '',
    timeline: '',
    budget: '',
    additionalServices: '',
    specialConsiderations: false, // Example for a checkbox
  });

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    const finalValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: finalValue });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSave(formData); // Your submit logic here
  };
  const handleSave = async () => {
    if (editMode) {
        try {
          const response = await axiosWithCredentials.put(`/crm/rfp/details/${formData.id}`, formData);
          if (response.status === 200 || response.status === 201) {
            console.log('Update successful');
            // loadItems();
          } else {
            console.error('Update failed');
            // Handle the upload failure (e.g., display error message)
          }
        } catch (error) {
          console.error('Error during Update', error);
          // Handle any errors that occurred during the upload process
        }
    } else {
        try {
          const response = await axiosWithCredentials.post(`/crm/rfp/details`, formData);
          if (response.status === 200 || response.status === 201) {
            console.log('Create successful');
            // loadItems();
          } else {
            console.error('Create failed');
            // Handle the upload failure (e.g., display error message)
          }
        } catch (error) {
          console.error('Error during Create', error);
          // Handle any errors that occurred during the upload process
        }
    }
    };


  return (
    <Container component="main" maxWidth="md">
      <Typography component="h1" variant="h5">
        Estate Plan Request for Proposal
      </Typography>
      <form onSubmit={handleSubmit}>
        {/* Full Name */}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="fullName"
          label="Full Name"
          name="fullName"
          autoComplete="name"
          value={formData.fullName}
          onChange={handleChange}
        />

        {/* Email */}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={formData.email}
          onChange={handleChange}
        />

        {/* Phone Number */}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Phone Number"
          name="phone"
          type="tel"
          value={formData.phone}
          onChange={handleChange}
        />

        {/* Location */}
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="location"
          label="Location"
          name="location"
          placeholder="City, State"
          value={formData.location}
          onChange={handleChange}
        />

        {/* Estimated Value of Estate */}
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="estateValue"
          label="Estimated Value of Estate"
          name="estateValue"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={formData.estateValue}
          onChange={handleChange}
        />

        {/* Objectives and Goals */}
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          multiline
          rows={4}
          id="objectives"
          label="Objectives and Goals"
          name="objectives"
          helperText="Outline the main objectives for your estate plan."
          value={formData.objectives}
          onChange={handleChange}
        />

        {/* Legal Requirements or Special Considerations */}
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          multiline
          rows={4}
          id="legalRequirements"
          label="Legal Requirements or Special Considerations"
          name="legalRequirements"
          helperText="Include any specific legal requirements or special considerations for your estate."
          value={formData.legalRequirements}
          onChange={handleChange}
        />

        {/* Special Considerations Checkbox */}
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={formData.specialConsiderations} onChange={handleChange} name="specialConsiderations" />}
            label="Check if there are any special considerations for beneficiaries (e.g., minors, special needs)"
          />
        </FormGroup>

        {/* Budget */}
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="budget"
          label="Budget for Estate Planning Services"
          name="budget"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          helperText="Provide an estimated budget range if possible."
          value={formData.budget}
          onChange={handleChange}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
        >
          Submit Request
        </Button>
      </form>
    </Container>
  );
};

export default RequestForProposal;
