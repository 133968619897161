import React, { useState } from 'react';
import { TextField, Button, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const WorkExperience = () => {
  const [workExperience, setWorkExperience] = useState([]);
  const [currentExperience, setCurrentExperience] = useState({ companyName: '', positionTitle: '', startDate: '', endDate: '', description: '' });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = (experience = {}) => {
    setCurrentExperience(experience);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleChange = (e) => {
    setCurrentExperience({ ...currentExperience, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (currentExperience.id) {
      setWorkExperience(workExperience.map(exp => exp.id === currentExperience.id ? currentExperience : exp));
    } else {
      setWorkExperience([...workExperience, { ...currentExperience, id: Date.now() }]);
    }
    handleCloseDialog();
  };

  const handleDelete = (id) => {
    setWorkExperience(workExperience.filter(exp => exp.id !== id));
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
        Add Experience
      </Button>
      <List>
        {workExperience.map((experience) => (
          <ListItem key={experience.id}>
            <ListItemText primary={experience.companyName} secondary={`${experience.positionTitle}, ${new Date(experience.startDate).toLocaleDateString()} - ${new Date(experience.endDate).toLocaleDateString()}`} />
            <Button onClick={() => handleOpenDialog(experience)}>Edit</Button>
            <Button onClick={() => handleDelete(experience.id)}>Delete</Button>
          </ListItem>
        ))}
      </List>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{currentExperience.id ? 'Edit Experience' : 'Add Experience'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Company Name"
            name="companyName"
            value={currentExperience.companyName}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Position Title"
            name="positionTitle"
            value={currentExperience.positionTitle}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Start Date"
            name="startDate"
            type="date"
            value={currentExperience.startDate}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date"
            name="endDate"
            type="date"
            value={currentExperience.endDate}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Description"
            name="description"
            value={currentExperience.description}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WorkExperience;
