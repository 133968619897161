import React, { useState } from 'react';
import { Box, Tabs, Tab, Card, CardContent, Typography } from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`health-tabpanel-${index}`}
      aria-labelledby={`health-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `health-tab-${index}`,
    'aria-controls': `health-tabpanel-${index}`,
  };
}

function HealthInsuance() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="health tabs">
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Medical" {...a11yProps(1)} />
          <Tab label="Dental" {...a11yProps(2)} />
          <Tab label="Vision" {...a11yProps(3)} />
          <Tab label="STC" {...a11yProps(4)} />
          <Tab label="LTD" {...a11yProps(5)} />
        </Tabs>
      </Box>
      {['Overview', 'Medical', 'Dental', 'Vision', 'STC', 'LTD'].map((tab, index) => (
        <TabPanel value={value} index={index} key={tab}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" component="h2">
                {tab} Plan Details
              </Typography>
              <Typography color="textSecondary">Effective Date: [Date Here]</Typography>
              <Typography color="textSecondary">Group ID: [Group ID Here]</Typography>
              <Typography color="textSecondary">Plan Coverage: [Details Here]</Typography>
              <Typography color="textSecondary">Enrolled Members: [Number Here]</Typography>
              <Typography color="textSecondary">Cost Breakdown: [Details Here]</Typography>
            </CardContent>
          </Card>
        </TabPanel>
      ))}
    </Box>
  );
}

export default HealthInsuance;
