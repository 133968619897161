import React from 'react';
import { Grid } from '@mui/material';
import SetupComponent from './SetupComponent';
import LogComponent from './LogComponent';
import ChatComponent from './ChatComponent';

function PersonalAssistant() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <SetupComponent />
      </Grid>
      <Grid item xs={12} md={4}>
        <LogComponent />
      </Grid>
      <Grid item xs={12} md={4}>
        <ChatComponent />
      </Grid>
    </Grid>
  );
}

export default PersonalAssistant;
