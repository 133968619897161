import React, { useState } from 'react';
import { Icon, FormInput, Button } from '@arcault-ui/shared-components';

const DependantInfo = ({ formData, setFormData }) => {
  const [childInfo, setChildInfo] = useState({})

  const updateChild = (e) => {
    const { name, value } = e.target;
    const newChildInfo = {...childInfo}
    newChildInfo[name] = value
    setChildInfo(newChildInfo)
  }
  
  const saveChild = () => {
    setFormData([...formData, childInfo])
    setChildInfo({})
  }
  
  const validChild = childInfo?.firstName && childInfo?.lastName && childInfo?.dateOfBirth
  
  const removeDependant = (id) => {
    setFormData(formData.filter(c => c.firstName !== id))
  }
  
  const calculateAge = (dateString) => {
    const [year, month, day] = dateString.split('-'); // Split the date string by '-'
    const birthDate = new Date(`${year}-${month}-${day}`); // Create a Date object

    const today = new Date(); // Get the current date
    let age = today.getFullYear() - birthDate.getFullYear(); // Calculate the initial age

    // Adjust if the birthday hasn't occurred yet this year
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--; // Subtract 1 if birthday hasn't passed yet this year
    }

    return age;
  }

  return (
    <>
 
      <div style={{ display: 'flex', gap: 16 }}>
        <FormInput
          label="First Name"
          id="firstName"
          value={childInfo?.firstName || ''}
          onChange={updateChild}
        />
        <FormInput
          label="Last Name"
          id="lastName"
          value={childInfo?.lastName || ''}
          onChange={updateChild}
        />
      </div>
      <FormInput
        label="Birthday"
        id="dateOfBirth"
        type="date"
        value={childInfo?.dateOfBirth || ''}
        onChange={updateChild}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button className="primary-button" isDisabled={!validChild} onClick={saveChild}>Add Dependant</Button>
      </div>
      
      <div style={{ 
        margin: '16px 0px 32px', 
        borderRadius: 8, 
        minHeight: 100, 
        borderTop: 'dashed 1px #cccccc', 
        flex: 1, 
        paddingTop: 8,
      }}>
        {(formData || []).map(c => (
          <div style={{ flex: 1, backgroundColor: '#2BB8A2', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '12px 0 12px', borderRadius: 12, padding: '8px 12px 8px', 
          }}><h4 style={{ fontWeight: 'bold', color: 'white'}}>{c.firstName} {c.lastName}</h4><div> {calculateAge(c.dateOfBirth)} years old</div> <div style={{ display: 'flex', alignItems: 'center' }}onClick={() => removeDependant(c.firstName)}><Icon name="delete" /></div></div>
        ))}
        {(formData?.length === 0) && <h4 style={{ color: '#a3a3a3' }}>No dependants added</h4>}
      </div>      
    </>
  );
};

export default DependantInfo;
