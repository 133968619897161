import React from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';

const DigitalDetails = ({ account , onEditAccount, onRemoveAccount }) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h2">
          {account.name}
        </Typography>
        <Typography color="textSecondary">
          Username: {account.username}
        </Typography>
        <Typography color="textSecondary">
          Email: {account.email}
        </Typography>
        <Typography variant="body2" component="p">
          Other details...
        </Typography>
               <Button onClick={() => onEditAccount(account)}>Edit</Button>
        <Button onClick={() => onRemoveAccount(account.id)}>Delete</Button>
      </CardContent>
    </Card>
  );
};

export default DigitalDetails;
