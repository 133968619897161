import React, { useState } from 'react';
import { Button, TextField, List, ListItem, ListItemText, Container, Typography } from '@mui/material';

function Favorites() {
  const [favorites, setFavorites] = useState([]);
  const [newFavorite, setNewFavorite] = useState({ name: '', category: '', reason: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewFavorite({ ...newFavorite, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newFavorite.name || !newFavorite.category || !newFavorite.reason) {
      alert('Please fill out all fields.');
      return;
    }
    setFavorites([...favorites, { ...newFavorite, id: Date.now() }]);
    setNewFavorite({ name: '', category: '', reason: '' }); // Reset form
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        My Favorite Things Catalog
      </Typography>
      <form onSubmit={handleSubmit} style={{ marginBottom: '2rem' }}>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          margin="normal"
          name="name"
          value={newFavorite.name}
          onChange={handleChange}
        />
        <TextField
          label="Category"
          variant="outlined"
          fullWidth
          margin="normal"
          name="category"
          value={newFavorite.category}
          onChange={handleChange}
        />
        <TextField
          label="Reason"
          variant="outlined"
          fullWidth
          margin="normal"
          name="reason"
          value={newFavorite.reason}
          onChange={handleChange}
        />
        <Button type="submit" variant="contained" color="primary">
          Add Favorite
        </Button>
      </form>
      <List>
        {favorites.map((item) => (
          <ListItem key={item.id}>
            <ListItemText primary={item.name} secondary={`${item.category} - ${item.reason}`} />
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default Favorites;
