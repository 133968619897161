import React from 'react';
import './Tooltip.css'; // Create a CSS file for styling

const Tooltip = ({ label, children }) => {
  return (
    <div className="tooltip-container">
      {children}
      <span className="tooltip-text">{label}</span>
    </div>
  );
};

export default Tooltip;