import { useEffect, useRef } from "react";
import { axiosWithCredentials } from "../api/axios";
import { fetchAuthSession} from '@aws-amplify/auth';


const useAxiosWithCredentials = () => {
    const authSessionRef = useRef(null);
    
    useEffect(() => {
        
        const requestIntercept = axiosWithCredentials.interceptors.request.use(
            async config => {
                console.log('useAxiosWithCredentials requestIntercept',  authSessionRef.current);
                let session
                if (!authSessionRef.current) {
                    console.log('useAxiosWithCredentials requestIntercept refetching    ',  authSessionRef.current);
                    session = await fetchAuthSession();
                } else {
                    session = authSessionRef.current;
                }
                if (session) {
                    if (!authSessionRef.current) authSessionRef.current = session;
                    // config.headers['Authorization'] = `Bearer ${user.signInUserSession.idToken.jwtToken}`;
                    config.headers['Authorization'] = `Bearer ${session.tokens.idToken}`;
                }
                return config;
            },(error) => Promise.reject(error)
        );
        
        
        const responseIntercept = axiosWithCredentials.interceptors.response.use(
            response => response,
            error => {
                // Handle error response
                return Promise.reject(error);
            }
        );

        return () => {
            axiosWithCredentials.interceptors.request.eject(requestIntercept);
            axiosWithCredentials.interceptors.response.eject(responseIntercept);
        };

    }, []);

    return axiosWithCredentials;
}

export default useAxiosWithCredentials;