import React from 'react';
import { Box, InputLabel, Select, MenuItem } from '@mui/material';
import { Text, Modal, Button, FormInput } from '@arcault-ui/shared-components';
import SpreadLine from '../SpreadLine';

const SecretDialog = ({
    viewingSecret,
    handleCloseSecret,
    settings,
    secret,
    editingSecret,
    handleSecretChange,
    handleEditSecret,
    cancelEditSecret,
    saveSecret
  }) => {

  const Footer = editingSecret && (
      <SpreadLine>
        <Button onClick={cancelEditSecret} className="outline" type="outline">
          Cancel
        </Button>
        <Button onClick={saveSecret} type="primary">
          Save Secret
        </Button>
      </SpreadLine>
  )
    
  return (
    <Modal title="Secret" isOpen={viewingSecret} onClose={handleCloseSecret} footer={Footer}>
        <Text variant="caption">
          Secrets add an additional layer of security and can be used to store things like account passwords and other sensitive information. Learn More <a>here</a>
        </Text>
        <br/>
          {/* secret.encryptionKey !== "default" && (
          <DecryptInstructions />
          )
          */}
          {settings.secrets?.useCustomKey && (
            <>
              <InputLabel id="encryption-key-select-label">Encryption Key</InputLabel>
              <Select
                labelId="encryption-key-select-label"
                id="encryption-key-select"
                fullWidth
                value={secret.encryptionKey}
                label="Encryption Key"
                disabled={!editingSecret}
                name="encryptionKey"
                onChange={handleSecretChange}
              >
                <MenuItem value="default">Arcault Managed</MenuItem>
                <MenuItem value={settings.secrets.keyName}>{settings.secrets.keyName}</MenuItem>
              </Select>
            </>
          )}
          <FormInput
            label="Secret Name"
            id="name"
            isDisabled={!editingSecret}
            value={secret.name}
            onChange={handleSecretChange}
          />
          {editingSecret && (
            <FormInput
              label="Secret"
              id="plaintext"
              value={secret.plaintext}
              onChange={handleSecretChange}
s            />
          )}
          {!editingSecret && (
            <Box>
              <Button onClick={handleEditSecret} sx={{ mt: 2 }} variant="contained" color="secondary" component="label">
                Retrieve Secret
              </Button>
            </Box>
          )}
    </Modal>
  );
};

export default SecretDialog;