import {useEffect, useState} from 'react';
import { Authenticator, Heading, Text,useTheme, View, Image, useAuthenticator, Button } from '@aws-amplify/ui-react'
import { Grid, Typography, Box, Link, Tabs, Tab} from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import '@aws-amplify/ui-react/styles.css'
import { ThemeProvider, createTheme } from '@aws-amplify/ui-react';

const theme = createTheme({
  name: 'custom-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          100: '#1f2c46', // Primary color
          80: '#2e405e',
        },
        secondary: {
          100: '#d1b278', // Secondary color
        },
        accent: {
          100: '#2cb9a2', // Accent color
        },
        neutral: {
          100: '#a3a3a3', // Neutral color
        }
      },
    },
    components: {
      button: {
        primary: {
          backgroundColor: { value: '#1f2c46' },
          color: { value: '#ffffff' },
        },
      },
      tabs: {
        tabItem: {
          color: { value: '#2cb9a2' }, // Use accent color for text
          borderColor: { value: '#2cb9a2' }, // Use accent color for border
        },
        indicator: {
          backgroundColor: { value: '#2cb9a2' }, // Indicator color for active tab
        },
      },
      link: {
        color: { value: '#2cb9a2' }, // Use accent color for links
        hover: {
          color: { value: '#1f2c46' }, // Change color on hover if needed
        },
      },
    },
  },
});




export default function Login() {
  const { route } = useAuthenticator(context => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  const { tokens } = useTheme();
  const [tabValue, setTabValue] = useState(0); // State for tab selection
  let from = location.state?.from?.pathname || "/";

  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  // Customized components
  const components = {
    Header() {
      return (
        <View textAlign="center" padding={tokens.space.large}>
          {/* Optionally add a logo or company name here */}
        </View>
      );
    },

    Footer() {
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; {new Date().getFullYear()} Arcault. All Rights Reserved.
          </Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Sign in to your account
          </Heading>
        );
      },
      Footer() {
        const { toForgotPassword } = useAuthenticator();
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toForgotPassword}
              size="small"
              variation="link"
            >
              Reset Password
            </Button>
          </View>
        );
      },
    },

    SignUp: {
      Header() {
        return (
          <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={3}>
            Create a new account
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
  };

  const formFields = {
    signIn: {
      username: {
        placeholder: 'Enter your email',
      },
    },
    signUp: {
      password: {
        label: 'Password:',
        placeholder: 'Enter your Password:',
        isRequired: false,
        order: 2,
      },
      confirm_password: {
        label: 'Confirm Password:',
        order: 1,
      },
    },
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh', // Ensure the box covers the entire height of the viewport
    }}
  >
    {/* Main content */}
    <Grid
      container
      sx={{
        flexGrow: 1, // Allow the grid to grow and take available space
      }}
    >
      {/* Left side: Banner and tagline */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: '#1f2c46',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 4,
          color: '#fff',
          minHeight: '80vh', // Ensuring the left side takes proper height
        }}
      >
        <Typography variant="h1" component="div" gutterBottom>
          Arcault
        </Typography>
        <Typography variant="h5" component="div" gutterBottom color="#d1b278">
          Life Planning just got simpler
        </Typography>
      </Grid>

      {/* Right side: Authenticator */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          backgroundColor: '#f9f9f9',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          minHeight: '80vh', // Ensuring the right side takes proper height
        }}
      >
        <Authenticator
          formFields={formFields}
          components={components}
          variation="default"
        />
      </Grid>
    </Grid>

    {/* Footer */}
    <Box
      sx={{
        backgroundColor: 'white',
        padding: 2,
        textAlign: 'center',
        borderTop: '1px solid #ccc',
        width: '100%',
        flexShrink: 0, // Prevent footer from shrinking
      }}
    >
      <Typography variant="body2">
        &copy; {new Date().getFullYear()} Arcault. All rights reserved.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 1 }}>
        <Link href="/terms" underline="hover" color="#2cb9a2">
          Terms
        </Link>
        <Link href="/privacy" underline="hover" color="#2cb9a2">
          Privacy
        </Link>
        <Link href="/help" underline="hover" color="#2cb9a2">
          Help
        </Link>
      </Box>
    </Box>
  </Box>
</ThemeProvider>
  );
}