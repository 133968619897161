const awsmobile = {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:51612beb-d1ba-46c9-b6db-1fbf95cd5016',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_YyFOhd4JU',
    aws_user_pools_web_client_id: '7kjg8e6bj3s4ditkbgnk6ik6ae',
    "oauth": {
        "domain": "dev-arcault-lifeplan2-auth.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.dev.arcault.com/oauth2/idpresponse",
        "redirectSignOut": "https://app.dev.arcault.com/logout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};
export default awsmobile;

