import React, { useState, useEffect } from 'react';
import { 
  Card, 
  Grid, 
  CardContent, 
  Typography, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  TextField
} from '@mui/material';

import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot }  from '@mui/lab';


const initialMedicines = []; // Your initial medicine data

export default function Medicines () {
  const [medicines, setMedicines] = useState(initialMedicines);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentMedicine, setCurrentMedicine] = useState({});

  const handleOpenDialog = (medicine) => {
    setCurrentMedicine(medicine || {});
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSave = () => {
    // Add/Edit logic here
    handleCloseDialog();
  };

  useEffect(() =>{
      setMedicines([
          {
            id: 1,
            name: 'pravastatin 20mg tablet',
            prescribedOn: "02/2/2016"
            }
      ]);
  }, []);
  // Sort medicines by prescribed date
  const sortedMedicines = medicines.sort((a, b) => new Date(b.prescribedOn) - new Date(a.prescribedOn));

  return (
    <div style={{ display: 'flex' }}>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Timeline position="left">
        {sortedMedicines.map((medicine, index) => (
          <TimelineItem key={medicine.id}>
            <TimelineSeparator>
              <TimelineDot />
              {index < medicines.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>{medicine.prescribedOn}</TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
      </Grid>
      <Grid item xs={8}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
        {sortedMedicines.map((medicine) => (
          <Card key={medicine.id} style={{ marginBottom: '10px' }}>
            <CardContent>
              <Typography variant="h6">{medicine.name}</Typography>
              {/* Display other details here */}
            </CardContent>
          </Card>
        ))}

        <Button variant="contained" onClick={() => handleOpenDialog()}>
          Add Medicine
        </Button>
        </div>
     </Grid>
     
     </Grid>
      

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add/Edit Medicine</DialogTitle>
        <DialogContent>
          {/* Form fields here */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
