import React, { useState } from 'react';
import { resetPassword, confirmResetPassword } from '@aws-amplify/auth';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Link,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [stage, setStage] = useState(1); // 1: Enter email, 2: Enter code and password
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') setEmail(value);
    if (name === 'code') setCode(value);
    if (name === 'newPassword') setNewPassword(value);
  };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    setMessage(null);
    setError(null);
    try {
      const output = await resetPassword({ username: email });
      if (output.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        setMessage(`A reset code has been sent to your email.`);
        setStage(2);
      }
    } catch (err) {
      setError(err.message || 'Failed to send reset code. Please try again.');
    }
  };

  const handleSubmitCode = async (e) => {
    e.preventDefault();
    setMessage(null);
    setError(null);
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: newPassword,
      });
      setMessage('Your password has been successfully reset. Redirecting to Sign In...');
      setTimeout(() => navigate('/signin'), 3000); // Redirect after 3 seconds
    } catch (err) {
      setError(err.message || 'Failed to reset password. Please try again.');
    }
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 8 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
          p: 4,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Arcault
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Password Reset
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {stage === 1
            ? "Enter your email address below and we'll send you a code to reset your password."
            : 'Enter the code sent to your email along with your new password.'}
        </Typography>

        {/* Stage 1: Enter Email */}
        {stage === 1 && (
          <form onSubmit={handleSubmitEmail} style={{ width: '100%' }}>
            <TextField
              margin="normal"
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={email}
              onChange={handleChange}
              required
            />
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            {message && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {message}
              </Alert>
            )}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 1 }}>
              Send Reset Code
            </Button>
          </form>
        )}

        {/* Stage 2: Enter Code and New Password */}
        {stage === 2 && (
          <form onSubmit={handleSubmitCode} style={{ width: '100%' }}>
            <TextField
              margin="normal"
              fullWidth
              label="Reset Code"
              name="code"
              value={code}
              onChange={handleChange}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="New Password"
              name="newPassword"
              type="password"
              value={newPassword}
              onChange={handleChange}
              required
            />
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            {message && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {message}
              </Alert>
            )}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 1 }}>
              Reset Password
            </Button>
          </form>
        )}

        <Button
          type="button"
          fullWidth
          variant="outlined"
          sx={{ mt: 1 }}
          onClick={() => navigate('/signin')}
        >
          Back to Sign In
        </Button>
      </Box>
      <Box mt={4} textAlign="center">
        <Typography variant="body2" color="textSecondary">
          &copy; 2024 Arcault
        </Typography>
        <Link href="#" variant="body2">
          Privacy Policy
        </Link>{' '}
        |{' '}
        <Link href="#" variant="body2">
          Terms of Service
        </Link>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
