import React, { useState, useEffect } from 'react';
import { Card } from '@arcault-ui/shared-components';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import axios from 'axios';
import DocumentsTable from '../common/DocumentsTable';
import InfoAssetsTable from '../common/infoAssets/InfoAssetsTable';

import SuggestionCards from '../common/suggestionCards/SuggestionCards';
import Section from '../common/Section';
import '../../App.css';

const category = "insurance"

const Insurance = () => {
  const axiosWithCredentials = useAxiosWithCredentials()
  const [documents, setDocuments] = useState([]);

  const loadFiles = async () => {
    try {
      // Replace this URL with your actual endpoint
      const response = await axiosWithCredentials.get('/files/'+category+"/");
      setDocuments(response.data); // Assuming the response contains your files
    // , isLoading: false, downloadUrl: null
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
  };

  useEffect(() => {
    loadFiles();
  }, []); 
  
  
  const handleUpload = async (file) => {
  try {
    // Step 1: Request a pre-signed URL from the Lambda function
    const response = await axiosWithCredentials.post('/files/upload/'+category, {
      fileName: file.name,
      fileType: file.type
    });

    const { uploadUrl } = response.data;
    console.log(uploadUrl)
    // Step 2: Upload the file to S3 using the pre-signed URL
    const s3Response = await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    });

    if (s3Response.status === 200) {
      console.log('File upload successful');
      loadFiles();
    } else {
      console.error('File upload failed');
      // Handle the upload failure (e.g., display error message)
    }
  } catch (error) {
    console.error('Error during file upload', error);
    // Handle any errors that occurred during the upload process
  }
};

const handleDelete = async (documentId) => {
  console.log("Delete function triggered for document ID:", documentId);
  try {
    // Step 1: Request a pre-signed URL from the Lambda function
    const response = await axiosWithCredentials.put('/files/delete', {
      fileKey: documentId
    });

    if (response.status === 200 || response.status === 201) {
      console.log('File delete successful');
      loadFiles();
    } else {
      console.error('File delete failed');
      // Handle the upload failure (e.g., display error message)
    }
  } catch (error) {
    console.error('Error during file delete', error);
    // Handle any errors that occurred during the upload process
  }
};

const handleDownload = (document) => {
  console.log("Download function triggered for document:", document);
  // Implement download logic here
};

  const fields = [
    { id: 'policy_number', label: 'Policy Number',column: true, form: true},
    {id: 'type', label: 'Type',column: true, form: true},
    {id: 'end_date', label: 'In Effect Until',column: true, form: true},
  ];
  
  return (
    <Card class="card-no-shadow" title="Insurance">
      <SuggestionCards />
      <InfoAssetsTable
        title="Insurance Policies"
        fields={fields}
        service="insurance"
        dialogTitle="Insurance Policy"
      />
      <Section>
        <h5 class="pt-3 pb-3">Insurance Policies</h5>
        <DocumentsTable
          documents={documents}
          handleUpload={handleUpload}
          handleDelete={handleDelete}
          handleDownload={handleDownload}
        />
      </Section>
    </Card>
  );
}
export default Insurance
