import React, { useState } from 'react';
import { Box, Typography, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import PeopleAssignment from '../common/PeopleAssignment';
const HealthCareProxyForm = () => {
  const [healthAgent, setHealthAgent] = useState('');


  const handleHealthAgentChange = (event) => {
    setHealthAgent(event.target.value);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Health Care Proxy Considerations
      </Typography>
      <Typography variant="h6" gutterBottom>
        Choosing a Health Care Proxy
      </Typography>
      <Typography paragraph>
        A Health Care Proxy is authorized to make medical decisions on your behalf if you're unable to communicate 
        your wishes due to illness or incapacity. This person should understand your values and wishes regarding medical treatment.
      </Typography>
      
      <PeopleAssignment label="Health Care Proxy" category="estate" type="HealthAgent" />
      

      <Typography variant="h6" gutterBottom>
        Trust and Understanding
      </Typography>
      <Typography paragraph>
        It's crucial that your Health Care Proxy fully understands your health care preferences and is willing to advocate 
        on your behalf, even under stressful circumstances. Choose someone who is trustworthy and assertive.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Availability and Willingness
      </Typography>
      <Typography paragraph>
        Your Health Care Proxy should be readily available in an emergency and willing to take on this responsibility.
        Ensure they are prepared and informed about their role.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Legal and Ethical Considerations
      </Typography>
      <Typography paragraph>
        The Health Care Proxy needs to understand the legal and ethical dimensions of medical decisions, including respecting 
        your wishes and considering what's in your best medical interests.
      </Typography>
    </Box>
  );
};

export default HealthCareProxyForm;
