import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Paper } from '@mui/material';

const LivingWillForm = () => {
  const [livingWillText, setLivingWillText] = useState('');
  const [file, setFile] = useState(null);

  const handleTextChange = (event) => {
    setLivingWillText(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    } else {
      formData.append('text', livingWillText);
    }
    console.log('Submitting form...');
    // Here you would typically handle form submission, e.g., sending formData to a backend
  };

  return (
    <Box sx={{ p: 4 }} component="form" onSubmit={handleSubmit}>
      <Typography variant="h4" gutterBottom>
        Living Will Details and Form
      </Typography>

      <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Understanding a Living Will
        </Typography>
        <Typography paragraph>
          A living will is a written statement detailing a person's desires regarding their medical treatment in circumstances in which they are no longer able to express informed consent, especially near the end of life.
        </Typography>
        <Typography variant="h6" gutterBottom>
          What It Covers
        </Typography>
        <Typography paragraph>
          - <strong>Life-Prolonging Treatments:</strong> Decisions about the use of life-sustaining measures.
          - <strong>Resuscitation:</strong> Preferences regarding the use of CPR or advanced cardiac life support.
          - <strong>Tube Feeding:</strong> Directions on the use of artificial nutrition and hydration.
          - <strong>Pain Management:</strong> Guidelines on the use of medication to manage pain and comfort, even if it hastens death.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Legal Requirements
        </Typography>
        <Typography paragraph>
          The requirements for a Living Will vary by jurisdiction, including the need for witnesses or notarization to ensure its legal validity.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Importance of Having a Living Will
        </Typography>
        <Typography paragraph>
          It ensures that your healthcare preferences are respected, relieves family members from making difficult medical decisions, and helps prevent conflicts among relatives.
        </Typography>
      </Paper>

      <Typography variant="h6" gutterBottom>
        Specify Your Health Care Wishes
      </Typography>
      <TextField
        label="Your Living Will Text"
        multiline
        fullWidth
        rows={4}
        value={livingWillText}
        onChange={handleTextChange}
        variant="outlined"
        sx={{ mt: 2, mb: 2 }}
      />

      <Typography variant="h6" gutterBottom>
        Or Upload a Document
      </Typography>
      <Button
        variant="contained"
        component="label"
        sx={{ mt: 1, mb: 2 }}
      >
        Upload File
        <input
          type="file"
          hidden
          onChange={handleFileChange}
        />
      </Button>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Submit Living Will
      </Button>
    </Box>
  );
};

export default LivingWillForm;
