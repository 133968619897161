import React from 'react';
import { Box, Typography, Paper, List, ListItem, ListItemText, Divider } from '@mui/material';

const IdentificationInfoComponent = ({ assistantPhoneNumber }) => {
  return (
    <Paper elevation={3} sx={{ p: 3, bgcolor: 'background.default', color: 'text.primary', borderRadius: 2 }}>
      <Typography variant="h5" component="h3" sx={{ mb: 2 }}>
        Why Store Your IDs Online?
      </Typography>
      <Typography variant="body1" paragraph>
        In today's digital age, having quick access to your personal identification forms is more crucial than ever. Whether you're traveling, accessing services, or need to verify your identity, our secure ID storage solution offers:
      </Typography>
      <List dense>
        <ListItem>
          <ListItemText primary="Convenience: Access your IDs anytime, anywhere." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Security: Your data is encrypted and stored securely." />
        </ListItem>
        <ListItem>
          <ListItemText primary="Backup: Never worry about losing your IDs again." />
        </ListItem>
      </List>
      <Divider sx={{ my: 2 }} />
      <Typography variant="h6" component="h4" sx={{ mb: 1 }}>
        Instant ID Retrieval
      </Typography>
      <Typography variant="body1" paragraph>
        Need your ID on the go? Simply text "<strong>Send My ID</strong>" to your virtual assistant at <strong>{assistantPhoneNumber}</strong>, and receive your identification directly on your phone. It's that easy!
      </Typography>
      <Typography variant="body2">
        Your privacy and security are our top priority. IDs are sent through a secure channel to ensure your information remains confidential.
      </Typography>
    </Paper>
  );
};

export default IdentificationInfoComponent;
