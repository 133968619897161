import { useState, useEffect, useRef } from 'react';
import { Grid, Paper, Button, Box, Typography, Dialog, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import axios from 'axios';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { PhotoCamera, CloudUpload } from '@mui/icons-material';
import RotateRightIcon from '@mui/icons-material/RotateRight';

function ImageUpload({imageLabels, category, keyFields=[], expiresIn=60 }) {
  const [images, setImages] = useState({ front: '', back: '' });
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [loading, setLoading] = useState({ front: false, back: false });
  const axiosWithCredentials = useAxiosWithCredentials();
  
  
  
  const [dragOver, setDragOver] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const fileInputRef = useRef(null);
const [imageRotations, setImageRotations] = useState({});
  
  const rotateImage = (side) => {
  setImageRotations(prevRotations => ({
    ...prevRotations,
    [side]: (prevRotations[side] || 0) + 90,
  }));
};

  const [documents, setDocuments] = useState([]);
  
  const handleUpload = async (event, side) => {
    const file = event.target.files[0];
    try {
      console.log(file.name)
      console.log(file.type)
      
      // Step 1: Request a pre-signed URL from the Lambda function
      const response = await axiosWithCredentials.post('/files/upload/'+category, {
      fileName: `${side}_${file.name}`,
      fileType: file.type
      });

    const { uploadUrl } = response.data;
    // Step 2: Upload the file to S3 using the pre-signed URL
    const s3Response = await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    });

    if (s3Response.status === 200) {
      console.log('File upload successful');
      //Load Images
    } else {
      console.error('File upload failed');
      // Handle the upload failure (e.g., display error message)
    }
  } catch (error) {
    console.error('Error during file upload', error);
    // Handle any errors that occurred during the upload process
  }
};



  const handleClickOpen = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  
  
  
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const handleFileSelect = (event) => {
  // Get the selected files from the file input
  const files = event.target.files;

  // Check if files are selected
  if (files && files.length > 0) {
    // Assuming you want to handle the first selected file
    const file = files[0];

    // Call your existing upload handler function
    // You might need to modify handleUpload to accept a File object
    handleUpload(file);
  }
};
  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    // const files = event.dataTransfer.files;
    // console.log(JSON.stringify(files))
    // handleUpload(files);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
        // Process each file individually
        for (let i = 0; i < files.length; i++) {
            console.log(files[i].name)
            console.log(files[i].type)
            handleUpload(files[i]);
        }
    }
  };
  
  
  
  async function handleDownload(fileKey) {
        setDownloading(true);
        setDownloadUrl(null);
        console.log(fileKey)
        try {
            const response = await axiosWithCredentials.post('/files/download', {
              fileKey,
            });
            console.log(JSON.stringify(response))
            setDownloadUrl(response.data.url);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }

        setDownloading(false);
    }

    const onDownloadLinkClick = () => {
        setDownloadUrl(null);
        setDownloading(false);
    };

  const DownloadButton = ({ fileKey }) => {
    return (
      
                
        <IconButton aria-label="download" onClick={() => handleDownload(fileKey)} disabled={downloading}>
            {downloading ? (
                <span>Loading...</span> // Replace with your spinner
            ) : downloadUrl ? (
                <a href={downloadUrl} target="_blank" rel="noopener noreferrer" download>open</a>
            ) : (
                <CloudDownloadIcon />
            )}
        </IconButton>
    );
};
  
  const loadImages = async () => {
    try {
      // Replace this URL with your actual endpoint
      const response = await axiosWithCredentials.get(`/files/${category}?expiresIn=${expiresIn}`);
      
     const fetchedDocuments = response.data;
    console.log(JSON.stringify(response.data))
    // Initialize a temporary storage for categorized images
    const categorizedImages = { front: [], back: [] };

    // Iterate through the fetched documents
    fetchedDocuments.forEach(doc => {
      // Assuming 'doc' has a property like 'fileName' that contains the file name
      if (doc.Key.indexOf("front") >0) {
        categorizedImages.front.push(doc.Url);
      } else if (doc.Key.indexOf("back") >0) {
        categorizedImages.back.push(doc.Url);
      }
    });

    // Update the images state with the new categorized images
    // If you need to maintain existing images in the state, you can merge them as needed
    setImages(categorizedImages);
    console.log(JSON.stringify(images))
    // , isLoading: false, downloadUrl: null
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
  };

  useEffect(() => {
    loadImages();
  }, []); 
  
  
  
const handleDelete = async (documentId) => {
  console.log("Delete function triggered for document ID:", documentId);
  try {
    // Step 1: Request a pre-signed URL from the Lambda function
    const response = await axiosWithCredentials.put('/files/delete', {
      fileKey: documentId
    });

    if (response.status === 200 || response.status === 201) {
      console.log('File delete successful');
      loadImages();
    } else {
      console.error('File delete failed');
      // Handle the upload failure (e.g., display error message)
    }
  } catch (error) {
    console.error('Error during file delete', error);
    // Handle any errors that occurred during the upload process
  }
};

  
  
  
  

  return (
    <Box>
    <Grid container spacing={2}>
  {imageLabels.map((side) => (
    <Grid   item xs={12} sm={6} md={4} lg={3} key={side} sx={{  }}>
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 100,
          width: 140, // Maintain aspect ratio for licenses
          position: 'relative', // For absolute positioning of the CircularProgress
          cursor: 'pointer',
          backgroundColor: (theme) => theme.palette.action.disabledBackground,
          overflow: 'hidden', // Ensure the image doesn't overflow the paper area
        }}
        onClick={() => images[side] ? handleClickOpen(images[side]) : document.getElementById(`file-input-${side}`).click()}
      >
        {!images[side] && (
          <IconButton color="primary" component="span">
            <CloudUploadIcon />
          </IconButton>
        )}
        <input
          id={`file-input-${side}`}
          type="file"
          hidden
          accept="image/*"
          onChange={(e) => handleUpload(e, side)}
        />
        {loading[side] && <CircularProgress size={24} sx={{ position: 'absolute' }} />}
        {images[side] && (
          <img
            src={images[side]}
            alt={`${side} view`}
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              transform: `rotate(${imageRotations[side] || 0}deg)`,
              transition: 'transform 0.2s',
            }}
          />
        )}
      </Paper>
      {images[side] && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
          <IconButton onClick={() => rotateImage(side)}><RotateRightIcon /></IconButton> {/* Rotate icon */}
        </Box>
      )}
    </Grid>
  ))}
</Grid>
<Box sx={{ mt: 2 }}>
  <Grid container spacing={2}>
    {keyFields.map((field) => (
      <Grid item xs={12} sm={6} key={field.label}>
        <Typography variant="subtitle1">{field.label}</Typography>
        <Typography variant="body1">{field.value}</Typography>
      </Grid>
    ))}
  </Grid>
</Box>



      <Dialog
        open={open}
        onClose={handleClose}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <img src={selectedImage} alt="Full size" style={{ maxWidth: '100%', maxHeight: '90vh' }} />
      </Dialog>
    </Box>
  );
}

export default ImageUpload;
