import React, { useState } from 'react';
import { TextField, Button, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const Charities = () => {
  const [charities, setCharities] = useState([]);
  const [currentCharity, setCurrentCharity] = useState({ name: '', notes: '', totalDonations: '' });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = (charity = {}) => {
    setCurrentCharity(charity);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleChange = (e) => {
    setCurrentCharity({ ...currentCharity, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (currentCharity.id) {
      setCharities(charities.map(c => c.id === currentCharity.id ? currentCharity : c));
    } else {
      setCharities([...charities, { ...currentCharity, id: Date.now() }]);
    }
    handleCloseDialog();
  };

  const handleDelete = (id) => {
    setCharities(charities.filter(c => c.id !== id));
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
        Add New Charity
      </Button>
      <List>
        {charities.map(charity => (
          <ListItem key={charity.id}>
            <ListItemText primary={charity.name} secondary={`Donations: $${charity.totalDonations}`} />
            <Button onClick={() => handleOpenDialog(charity)}>Edit</Button>
            <Button onClick={() => handleDelete(charity.id)}>Delete</Button>
          </ListItem>
        ))}
      </List>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{currentCharity.id ? 'Edit Charity' : 'New Charity'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            value={currentCharity.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Notes"
            name="notes"
            value={currentCharity.notes}
            onChange={handleChange}
            fullWidth
            multiline
            margin="normal"
          />
          <TextField
            label="Total Donations"
            name="totalDonations"
            value={currentCharity.totalDonations}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Charities;
