import React, { useState, useEffect } from 'react';
import { Tabs, Typography, Tab, Box, Grid, Paper } from '@mui/material';
import PeopleAssignment from '../common/PeopleAssignment'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tax-tabpanel-${index}`}
      aria-labelledby={`tax-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Grid container spacing={2}>
            {children}
          </Grid>
        </Box>
      )}
    </div>
  );
}



  


const Accountant = () => {

    
  const [selectedTab, setSelectedTab] = useState(0);
  const taxYears = ['2023', '2022', '2021']; // Example years

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" gutterBottom>
        Accountant
      </Typography>
      <Typography variant="h6" gutterBottom>
        Selecting an Accountant
      </Typography>
      <Typography paragraph>
        A Durable Power of Attorney is a legal document that grants someone authority to handle your financial and legal affairs if you are unable to do so. Unlike a standard Power of Attorney, it remains effective if you become incapacitated.
      </Typography>
      <PeopleAssignment label="Durable Power of Attorney" category="estate" type="dpoa"/>

    </Box>
  );
};

export default Accountant;
