import React from 'react';
import { Modal, FormInput, Button } from '@arcault-ui/shared-components';
import SpreadLine from '../SpreadLine';

const FormDialog = ({ open, handleClose, editMode, dialogTitle, fields, formData, handleFormChange, handleSave }) => {
  return (
    <Modal
        isOpen={open}
        onClose={handleClose}
        title={editMode ? `Edit ${dialogTitle}` : `Add ${dialogTitle}`}
        footer={(
            <SpreadLine>
                <Button type="outline" onClick={handleClose}>Cancel</Button>
                <Button type="primary"  onClick={handleSave}>Save</Button>
            </SpreadLine>
        )}
    >
        {fields.map((field) => (
            <FormInput
                id={field.id}
                label={field.label}
                type="text"
                value={formData[field.id]}
                onChange={handleFormChange}
                key={field.id}
            />
        ))}
    </Modal>
  );
};

export default FormDialog;