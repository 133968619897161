import { useState } from 'react';
import {
  FormInput, Checkbox
} from '@arcault-ui/shared-components'

const RelationshipInfo = ({formData, handleInputChange }) => {
  const [isDifferentAddress, setIsDifferentAddress] = useState(false); 

  const handleAddressChange = (event) => {
    if(!isDifferentAddress){
      formData.partnerAddress = formData.address;
    } else {
      formData.partnerAddress = '';
    }
    setIsDifferentAddress(!isDifferentAddress)
    handleInputChange({
      target: {
        name: 'partnerAddress',
        value: formData.partnerAddress
      }
    })
  };    
    
  return (
    <>
      <div style={{ display: 'flex', gap: 16 }}>
      <FormInput
        label="Partner's First Name"
        id="firstName"
        value={formData?.firstName || ''}
        onChange={handleInputChange}
      />
      <FormInput
        label="Partner's Last Name"
        id="lastName"
        value={formData?.lastName || ''}
        onChange={handleInputChange}
      />
      </div>
      <FormInput
        label="Partner's Birthday"
        id="dateOfBirth"
        type="date"
        value={formData?.dateOfBirth || ''}
        onChange={handleInputChange}
      />
      <FormInput
        label="Partner's Phone"
        id="phone"
        value={formData?.phone || ''}
        onChange={handleInputChange}
      />
      <FormInput                   
        label="Partner's Email"
        id="email"
        value={formData?.email || ''}
        onChange={handleInputChange}
      />
      <Checkbox onChange={handleAddressChange} isChecked={!isDifferentAddress} label="Different Address" />
      <FormInput
        label="Partner's Address"
        id="address"
        isDisabled={isDifferentAddress}
        value={formData?.address || ''}
        onChange={handleInputChange}
        disabled={!isDifferentAddress} // Disable if isSameAddress is true
      />
    </>
  );
}


export default RelationshipInfo;