import React, { useState, useEffect } from 'react';
import { Switch, FormControlLabel, TextField, Button, Box, Typography } from '@mui/material';
import RsaKeyInstructions from './RsaKeyInstructions'


import {useSettings} from '../../../context/SettingsContext'


function SplashToggle() {
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const {settings = {} , updateSettings} = useSettings();

    useEffect(() => {
      setShowSplashScreen(settings.views?.showSplashScreen)
    }, [settings]); 
  
  const handleToggleChange = (event) => {
    const isChecked = event.target.checked
    setShowSplashScreen(isChecked);
    // Optionally reset publicKey and inputName when toggled off
    console.log("Setting splash ",event.target.checked )
      // Compute new settings directly in the component
    const newSettings = {
      ...settings,
      views: {
        ...settings.views,
        showSplashScreen: isChecked,
        splashScreenIndex: 0 //whenever toggled just set to zero
      }
    };

      updateSettings(newSettings)
  };

  return (
    <Box>
      <FormControlLabel
        control={
        <Switch
          name="showSplashScreen"
          checked={showSplashScreen}
          onChange={handleToggleChange}
        />
      }
        label="Show Splash Screen"
      />
      
    </Box>
  );
}
export default SplashToggle