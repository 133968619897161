import React from 'react';
import { List, ListItem, ListItemText, Button } from '@mui/material';
import Divider from '@mui/material/Divider';

const DigitalList = ({ accounts, onSelectAccount, onAddAccount }) => {
  return (
    <>
    <Button onClick={() => onAddAccount({ /* New Account Details */ })}>Add Account</Button>
    <List component="nav" aria-label="secondary mailbox folders">
      {accounts.map(account => (
        <React.Fragment key={account.id}>
          <ListItem button onClick={() => onSelectAccount(account)}>
            <ListItemText primary={account.name} />
          </ListItem>
          <Divider />
        </React.Fragment>
        
      ))}
    </List>
    </>
  );
};

export default DigitalList;
