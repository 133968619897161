import { useEffect } from 'react';
import { Checkbox, FormInput, Select } from '@arcault-ui/shared-components';

import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials'

const relationshipStatuses = [
  'Married', 'Single', 'Divorced', 'Widowed', 'Engaged', 
  'Domestic Partnership', 'Civil Union', 'Life Partner'
];

const BasicInfo = ({formData, setFormData, handleInputChange, setInitialFormData}) => {
  const axiosWithCredentials = useAxiosWithCredentials();
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try{
        
        const response = await axiosWithCredentials.get('/users/profile');
        const basicInfo = response.data
        // Update the formData state with the retrieved basicInfo
        setFormData(prevFormData => ({
          ...prevFormData,
          ...basicInfo
        }));
        setInitialFormData(basicInfo);
        
      }
      catch(err){
        console.log(err)
    }
  };
    

  const inputDoubles = [
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
  ]
  
  const inputSingles = [
    { id: "email", label: "Email Address", type: "email" },
    { id: "address", label: "Primary Address" },
    { id: "birthdate", label: "Birthday", type: "date" },
  ]

  const handleChangeHasDependants = (e) => {
    e.target.value = e.target.checked
    handleInputChange(e)
  }
        
  return (
    <>
      <div style={{ display: 'flex', gap: 16 }}>
        {inputDoubles.map(i => (
          <FormInput id={i.id} label={i.label} value={formData[i.id]} type={i.type || 'text'} onChange={handleInputChange}/>
        ))}
      </div>
      {inputSingles.map(i => (
        <FormInput id={i.id} label={i.label} value={formData[i.id]} type={i.type || 'text'} onChange={handleInputChange}/>
      ))}

      <Select 
        label="Relationship Status"
        isRequired
        name="relationship" 
        onChange={handleInputChange} 
        value={formData['relationship']}
        className={`${formData['relationship'] ? 'input-filled' : 'input-empty'}`}
        placeholder='Select status'
        options={relationshipStatuses.map(status => ({ value: status, label: status }))}
      />
      <Checkbox name="hasDependants" onChange={handleChangeHasDependants} defaultChecked label="I have dependants" />
    </>
  );
}


export default BasicInfo;