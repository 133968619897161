import { useState, useEffect } from 'react';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import { Card } from '@arcault-ui/shared-components';
import axios from 'axios';
import DigitalVault from '../common/digitalVault/DigitalVault';
import InfoAssetsTable from '../common/InfoAssetsTable.1';
import SuggestedNextStep from '../common/SuggestedNextStep';


const category = "insurance"


const Assets = () => {
  
  const axiosWithCredentials = useAxiosWithCredentials()
  
  const [assets, setAssets] = useState([]);

  // const loadFiles = async () => {
  //   try {
  //     // Replace this URL with your actual endpoint
  //     const response = await axiosWithCredentials.get('/files/'+category+"/");
  //     console.log(JSON.stringify(response))
  //     setDocuments(response.data); // Assuming the response contains your files
  //   // , isLoading: false, downloadUrl: null
  //   } catch (error) {
  //     console.error("Error loading files:", error);
  //     // Handle errors appropriately
  //   }
  // };
  
  
  const fields = [
    { id: 'description', label: 'Description',column: true, form: true},
    {id: 'estimatedValue', label: 'Estimated Value',column: true, form: true}
  ];
  const assetTypes = [
    { assetType: 'Car', fields: [{ id: 'year', label: 'Year' },{ id: 'make', label: 'Make' }, { id: 'model', label: 'Model' }] },
    { assetType: 'House', fields: [{ id: 'address', label: 'Address' }, { id: 'size', label: 'Size (sqft)' }, { id: 'bedrooms', label: 'Bedrooms' },{ id: 'baths', label: 'Baths' }, { id: 'lot', label: 'Lot Size (acres)' }] },
    { assetType: 'Boat', fields: [{ id: 'address', label: 'Address' }, { id: 'size', label: 'Size (sqft)' }] },
    { assetType: 'Collection', fields: [{ id: 'items', label: 'Items' }, { id: 'estimatedValue', label: 'Estimated Value' }] },
    // Add more asset types and their specific fields here
  ];
  
  const loadAssets = async () => {
    try {
      const response = await axiosWithCredentials.get('/fo/assets');
      return response.data.Items; 
    } catch (error) {
      console.error("Error loading assets:", error);
      // Handle errors appropriately
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      const data = await loadAssets();
      if (data) {
        setAssets(data);
      }
    };

    fetchData();
}, []);
  
  /* const handleUpload = async (file) => {
  try {
    // Step 1: Request a pre-signed URL from the Lambda function
    const response = await axiosWithCredentials.post('/files/upload/'+category, {
      fileName: file.name,
      fileType: file.type
    });

    const { uploadUrl } = response.data;
    console.log(uploadUrl)
    // Step 2: Upload the file to S3 using the pre-signed URL
    const s3Response = await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    });

    if (s3Response.status === 200) {
      console.log('File upload successful');
      // loadFiles();
    } else {
      console.error('File upload failed');
      // Handle the upload failure (e.g., display error message)
    }
  } catch (error) {
    console.error('Error during file upload', error);
    // Handle any errors that occurred during the upload process
  }
};

const handleDownload = (document) => {
  console.log("Download function triggered for document:", document);
  // Implement download logic here
};*/

  return (
    <Card className="card-no-shadow dashboard">
      <InfoAssetsTable
        title="Assets"
        fields={fields}
        assetTypes={assetTypes}
        service="assets"
      />
    </Card>  
  );
}
export default Assets
