// OAuthCallback.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { fetchAuthSession, getCurrentUser} from '@aws-amplify/auth';

const OAuthCallback = () => {
  const navigate = useNavigate();

  // useEffect(() => {
    // // const handleAuthCallback = async () => {
    // //   try {
    // //     const urlParams = new URLSearchParams(window.location.search);
    // //     const authCode = urlParams.get('code');

    // //     if (authCode) {
    // //       console.log('Authorization code received:', authCode);
    // //       // Trigger the federated sign-in
    // //       await federatedSignIn({ provider: 'Google' });
    // //     } else {
    // //       console.error('No authorization code found in the URL.');
    // //       navigate('/error'); // Redirect to an error page if needed
    // //     }
    // //   } catch (error) {
    // //     console.error('Error during federated sign-in:', error);
    // //     navigate('/error'); // Redirect to an error page if needed
    // //   }
    // };

    // handleAuthCallback();
  //   async () => {
  //     const session = await fetchAuthSession();
  //     console.log("Session", session.tokens.idToken)
  //     navigate('/');
  //   }
    
  // }, []);
useEffect(() => {
  const fetchSession = async () => {
    try {
      const session = await fetchAuthSession();
      console.log("Session", session.tokens.idToken);
      const { username, userId, signInDetails } = await getCurrentUser();
    console.log(`The username: ${username}`);
    console.log(`The userId: ${userId}`);
    console.log(`The signInDetails: ${signInDetails}`);
      navigate('/');
    } catch (error) {
      console.error("Failed to fetch session:", error);
    }
  };

  fetchSession(); // Call the async function inside useEffect
}, []);
  return (
    <div>
      <h2>Authenticating...</h2>
    </div>
  );
};

export default OAuthCallback;
