import React, { useState, useEffect } from 'react';
import { 
  Card, 
  Grid, 
  CardContent, 
  Typography, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  TextField
} from '@mui/material';

import DoctorCard from './DoctorCard'
import DoctorProfileCard from './DoctorProfileCard'

import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot }  from '@mui/lab';


const initialMedicines = []; // Your initial medicine data

const Care = () => {
  const [medicines, setMedicines] = useState(initialMedicines);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentMedicine, setCurrentMedicine] = useState({});

  const handleOpenDialog = (medicine) => {
    setCurrentMedicine(medicine || {});
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSave = () => {
    // Add/Edit logic here
    handleCloseDialog();
  };

  useEffect(() =>{
      setMedicines([
          {
            id: 1,
            name: 'pravastatin 20mg tablet',
            prescribedOn: "02/2/2016"
            }, {
            id: 2,
            name: 'pravastatin 20mg tablet'
            }
      ]);
  }, []);
  // Sort medicines by prescribed date
  const sortedMedicines = medicines.sort((a, b) => new Date(b.prescribedOn) - new Date(a.prescribedOn));

  return (
    <div style={{ display: 'flex' }}>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <DoctorCard
        name="Dr. Jane Doe"
        category="Primary Care Provider"
        department="Internal Medicine"
        imageUrl="https://via.placeholder.com/150"
      />
      <DoctorCard
        name="Dr. Jane Doe"
        category="Primary Care Provider"
        department="Internal Medicine"
        imageUrl="https://via.placeholder.com/150"
      />
      <DoctorCard
        name="Dr. Jane Doe"
        category="Primary Care Provider"
        department="Internal Medicine"
        imageUrl="https://via.placeholder.com/150"
      />
      </Grid>
      <Grid item xs={8}>
        <DoctorProfileCard name="Dr. William Boyd" category="primary care"/>
     </Grid>
     
     </Grid>
    </div>
  );
};

export default Care;
