// theme.js
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    primary: {
      main: '#1976d2', // Replace with your primary color
    },
    secondary: {
      main: '#dc004e', // Replace with your secondary color
    },
    background: {
      default: '#ffffff', // Set your default background
    },
    secondary: {
      50: '#E5C995',  // Lightest gold tone
      100: '#E1C48E',
      200: '#DCBF88',
      300: '#D8BA81',
      400: '#D1B277',  // Medium gold tone
      500: '#CEAF74',
      600: '#C8A86E',
      700: '#C1A167',
      800: '#BB9A61',
      900: '#B5935B',  // Darkest gold tone
    },
    accent: {
      50: '#1E7E73',  // Darker mint teal tone
      100: '#218B7D',
      200: '#249888',
      300: '#27A592',
      400: '#2BB8A2',  // Main mint teal tone
      500: '#31BAA5',
      600: '#3EBFAA',
      700: '#4AC4AF',
      800: '#57C8B5',
      900: '#63CDBA',  // Light mint teal tone
    },
    neutral: {
      50: '#F5F5F5',
      100: '#E3E3E3',
      200: '#D1D1D1',
      300: '#BEBEBE',
      400: '#ACACAC',
      500: '#A3A3A3', /* Medium neutral */
      600: '#7E7E7E',
      700: '#656565',
      800: '#4C4C4C',
      900: '#333333', 
    }
  },
  fonts: {
    heading: 'Montserrat, Arial, sans-serif',
    body: 'Montserrat, Arial, sans-serif',
  },
 space: {
    0: '0px',
    1: '4px',  // This represents 1 * 4px
    2: '8px',  // This represents 2 * 4px
    3: '12px', // 3 * 4px, and so on
    4: '16px',
    5: '20px',
    6: '24px',
    8: '32px',
    10: '40px',
    12: '48px',
    16: '64px',
    20: '80px',
 },
});

export default theme;
