import React from 'react';
import { Typography, Box, Paper, List, ListItem, ListItemText, ListItemIcon, Container } from '@mui/material';
import TerminalIcon from '@mui/icons-material/Terminal'; // Assuming custom icon or find a suitable one

function RsaKeyInstructions() {
  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          How to Generate an RSA Key Pair on macOS
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <TerminalIcon />
            </ListItemIcon>
            <ListItemText primary="Open the Terminal application on your Mac." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <TerminalIcon />
            </ListItemIcon>
            <ListItemText primary='Generate the Private Key by entering the command: `openssl genpkey -algorithm RSA -out private_key.pem -pkeyopt rsa_keygen_bits:2048`' />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <TerminalIcon />
            </ListItemIcon>
            <ListItemText primary='Extract the Public Key with the command: `openssl rsa -pubout -in private_key.pem -out public_key.pem`' />
          </ListItem>
        </List>
        <Box mt={2}>
          <Typography variant="body1">
            Store these files securely and provide the public key when required by the application.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}
export default RsaKeyInstructions