import { useState, useEffect } from 'react';
import DependantInfo from '../common/DependantInfo';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import { Grid, Button, Box } from '@mui/material';

export default function Basics({ user }) {
  const axiosWithCredentials = useAxiosWithCredentials();
  const [dependantData, setDependantData] = useState([]);
  const [formDataChanged, setFormDataChanged] = useState(false);
  const [initialFormData, setInitialFormData] = useState(null);

  useEffect(() => {
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    try {
      const response = await axiosWithCredentials.get(`/fo/basics/${user.id}`);
      setInitialFormData(response.data);
      setDependantData(response.data.dependantData || []);
      setFormDataChanged(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      let i=0
      const savePromises = dependantData.map(async (dependant) => {
        console.log(`D${i++}`, dependant)
        if (dependant.id) {
          // Use PUT for existing records with an ID
          return axiosWithCredentials.put(`/fo/contacts/family/${dependant.id}`, dependant);
        } else {
          // Use POST for new records without an ID
          return axiosWithCredentials.post(`/fo/contacts/family`, dependant);
        }
      });

      await Promise.all(savePromises);

      setFormDataChanged(false);
      loadInitialData(); // Refresh the data after saving
      alert("Data saved successfully!");
    } catch (err) {
      console.log(err);
      alert("Error saving data. Please try again.");
    }
  };

  const dependantDataChange = () => {
    setFormDataChanged(true);
  };

  const handleReset = () => {
    console.log("Resetting changes");
    setDependantData(initialFormData.dependantData || []);
    setFormDataChanged(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <form>
          {/* Your BasicInfo component */}
          <DependantInfo
            key={initialFormData ? initialFormData.id : 'new'}
            dependantData={dependantData}
            setDependantData={setDependantData}
            handleDependantInputChange={dependantDataChange}
          />

          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              disabled={!formDataChanged}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              disabled={!formDataChanged}
              onClick={handleReset}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
}
