import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Switch, FormControlLabel, List, ListItem, ListItemText, Container, Typography } from '@mui/material';

import {useSettings} from '../../context/SettingsContext'

import EncryptionKeyToggle from './settings/EncryptionKeyToggle'
import SplashToggle from './settings/SplashToggle'

// Main settings component
const Settings = () => {
  const { section } = useParams();
  const navigate = useNavigate();

  // Toggle state for simple settings
  // const [settings, setSettings] = React.useState({
  //   darkMode: false,
  //   notifications: false,
  // });
  const {settings = {} , updateSettings} = useSettings();

  const handleToggle = (setting) => {
    // setSettings((prevSettings) => ({
    //   ...prevSettings,
    //   [setting]: !prevSettings[setting],
    // }));
    console.log((JSON.stringify(setting)))
  };

  // Settings navigation
  if (section) {
    return <AdditionalSettings section={section} />;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Settings</Typography>
      <List>
        <ListItem>
          <ListItemText primary="Dark Mode" />
          <Switch
            checked={settings.darkMode}
            onChange={() => handleToggle('darkMode')}
          />
        </ListItem>
        <ListItem>
          <ListItemText primary="Notifications" />
          <Switch
            checked={settings.notifications}
            onChange={() => handleToggle('notifications')}
          />
        </ListItem>
        <ListItem button onClick={() => navigate('/profile/settings/wifi')}>
          <ListItemText primary="Wi-Fi Settings" />
        </ListItem>
        <ListItem button onClick={() => navigate('/profile/settings/about')}>
          <ListItemText primary="About" />
        </ListItem>
      </List>
    </Container>
  );
};

// Component for additional settings based on the section
const AdditionalSettings = ({ section , settings, updateSettings}) => {
  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        {section.charAt(0).toUpperCase() + section.slice(1)} Settings
      </Typography>
      {/* Example content for additional settings */}
      {section === 'secrets' ? (
      <>
        <Typography variant="body1"></Typography>
        
        <EncryptionKeyToggle settings={settings} updateSettings={updateSettings}/>
      </>  
      ) : 
      section === 'info' ? (
      <>
        <SplashToggle/>
        
      </>  
      ) : (
        <Typography variant="body1">About the app content here...</Typography>
      )}
    </Container>
  );
};

export default Settings;
